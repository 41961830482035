<app-small-header
  title="Privatstunden"
  [breadcrumb]="breadCrumb"
></app-small-header>
<div class="content">
  <div class="container">
    @for(teacher of teachers; track $index) {
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body row">
            <div class="col-md-3">
              <img
                [src]="
                  teacher.imagePath
                    ? imagePath + teacher.imagePath
                    : './../../../../assets/default_box.webp'
                "
                class="rounded-circle img-thumbnail"
                alt=""
              />
            </div>
            <div class="col-md-9">
              <h3 class="card-title">
                {{ teacher.name }}
              </h3>
              @if(teacher.short && teacher.short !== '') {
              <ul>
                <li>{{ teacher.short }}</li>
              </ul>
              }
              <div class="row desc">
                @if(teacher.topics && teacher.topics !== '') {
                <div class="col-lg-6">
                  <h5>Themengebiete</h5>
                  <ul>
                    @if(teacher.topics && teacher.topics !== '') { @for(topic of
                    splitTopics(teacher.topics); track $index) {
                    <li>{{ topic }}</li>
                    } } @else {
                    <li>Keine Themengebiete angegeben</li>
                    }
                  </ul>
                </div>
                } @if(teacher.times && teacher.times !== '') {
                <div class="col-lg-6">
                  <h5>Privatstundenzeiten</h5>
                  <ul>
                    @if(teacher.times && teacher.times !== '') { @for(time of
                    splitTopics(teacher.times); track $index) {
                    <li>
                      {{ time }}
                    </li>
                    } } @else {
                    <li>Keine Zeiten angegeben</li>
                    }
                  </ul>
                </div>
                }
              </div>
            </div>
          </div>

          <a class="card-footer" (click)="openBooking(teacher)"
            >Privatstunde anfragen</a
          >
        </div>
      </div>
    </div>
    }
  </div>
</div>
