import { NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-header',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './big-header.component.html',
  styleUrl: './big-header.component.scss',
})
export class BigHeaderComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() image?: string;
  url = '';

  ngOnInit(): void {
    if (this.image) this.url = `url("${this.image}")`;
  }
}
