import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CourseFee } from '../_models/course-fee.modal';

@Injectable({
  providedIn: 'root',
})
export class CourseFeeService {
  private readonly url = environment.url + 'coursefee';
  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<CourseFee[]> {
    return this.http.get<CourseFee[]>(this.url);
  }
}
