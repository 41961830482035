import {
  Component,
  HostListener,
  LOCALE_ID,
  afterNextRender,
} from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';
import { Cours, Event, Level } from '../../../_models/nimbuscloud.model';
import { ActivatedRoute } from '@angular/router';
import { NimbuscloudService } from '../../../_services/nimbuscloud.service';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppointmentDialogComponent } from '../../../_dialogs/appointment-dialog/appointment-dialog.component';
import { BookingDialogComponent } from '../../../_dialogs/booking-dialog/booking-dialog.component';

registerLocaleData(localeDe);

@Component({
  selector: 'app-courses-detail',
  standalone: true,
  imports: [
    CommonModule,
    SmallHeaderComponent,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
  ],
  templateUrl: './courses-detail.component.html',
  styleUrl: './courses-detail.component.scss',
})
export class CoursesDetailComponent {
  public title: string = 'Kursdetails';
  public id: string = '';
  public typeId: string = '';

  public level?: Level;
  public levelDescription?: SafeHtml;

  public courses: Cours[] = [];

  public innerWidth = 999;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    route: ActivatedRoute,
    private nimbus: NimbuscloudService,
    private dialog: MatDialog,
    private meta: Meta,
    private sanitizer: DomSanitizer
  ) {
    afterNextRender(() => {
      this.onResize();
      this.innerWidth = window.innerWidth;
    });
    route.paramMap.subscribe((params) => {
      let id = params.get('id');
      if (id && id !== '') {
        this.id = id;
      }
      let typeId = params.get('typeId');
      if (typeId && typeId !== '') {
        this.typeId = typeId;
      }
      let title = params.get('title');
      if (title && title !== '') {
        this.title = title;
      }
      setTimeout(() => {
        this.getTypes();
        this.getCourses();
      });
    });
  }

  ngOnInit(): void {
    this.meta.updateTag({
      name: 'description',
      content:
        this.title +
        ' - ADTV Tanzschule Seidl Amberg - Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunde, Discofox, Salsa, Hip Hop, Ballett, Zumba, Kinder-Teenie-Tanzen, Linedance uvm',
    });
  }

  getTypes() {
    this.nimbus.getTypes().subscribe((data) => {
      if (data.statuscode === 200) {
        const type = data.content.types.find((t) => +t.id === +this.typeId);
        const level = type?.levels.find((l) => +l.id === +this.id);
        if (level?.description) {
          // remove style attributes
          this.levelDescription = this.sanitizer.bypassSecurityTrustHtml(
            level.description
              .replace(/(<[^>]+) style=".*?"/i, '$1')
              .replace(/(<[^>]+) [^>]+/gi, '$1')
          );
        }
        this.level = level;
        this.loadMeta();
      }
    });
  }

  loadMeta() {
    if (this.level) {
      let description = this.level.description.replace(/<(.|\n)*?>/g, '');
      description = description
        .replace('&amp;', '&')
        .replace(/[&]nbsp[;]/gi, ' ')
        .replace(/[<]br[^>]*[>]/gi, '')
        .replace(/[\n\t]+/g, '')
        .replace(/\s+/g, ' ')
        .trim();
      const teaser = this.level.teaser
        ? this.level.teaser
        : this.level.externalName;
      this.meta.updateTag({
        name: 'description',
        content: teaser + ' - ' + description + ' - Tanzen in Amberg',
      });
    }
  }

  getCourses() {
    this.nimbus.getCourses(this.typeId, this.id).subscribe((data) => {
      if (data.statuscode === 200) {
        this.courses = data.content.courses;
        this.courses.forEach((c) => {
          if (+c.startEventDate)
            c.startEventDate = DateTime.fromMillis(+c.startEventDate * 1000)
              .setLocale('de')
              .setZone('Europe/Berlin')
              .toFormat('EEEE, dd.MM.yyyy');
          if (+c.startEventStartTime)
            c.startEventStartTime = DateTime.fromMillis(
              +c.startEventStartTime * 1000
            )
              .setLocale('de')
              .setZone('Europe/Berlin')
              .toFormat('HH:mm');
          if (+c.startEventEndTime)
            c.startEventEndTime = DateTime.fromMillis(
              +c.startEventEndTime * 1000
            )
              .setLocale('de')
              .setZone('Europe/Berlin')
              .toFormat('HH:mm');
        });
      }
    });
  }

  getEventFuture(events: Event[]) {
    return events.filter((e) => e.isPastEvent === '0' && e.visible === '1');
  }

  openEvents(events: Event[]) {
    this.dialog.open(AppointmentDialogComponent, { data: events });
  }

  openBooking(cours: Cours) {
    this.dialog.open(BookingDialogComponent, {
      data: cours,
      width: '95vw',
      panelClass: 'full-screen-modal',
    });
  }
}
