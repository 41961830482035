import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';

@Component({
  selector: 'app-agb',
  standalone: true,
  imports: [CommonModule, SmallHeaderComponent],
  templateUrl: './agb.component.html',
  styleUrl: './agb.component.scss',
})
export class AgbComponent {}
