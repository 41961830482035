<app-small-header
  title="Unser Jubiläum, dein Deal zum Feiern!"
></app-small-header>

<div class="content">
  <div class="container">
    <h2 class="text-primary">
      15 Jahre Tanzschule Seidl - das muss gefeiert werden!
    </h2>
    <h3 class="text-primary">
      Was passt da besser als ein unschlagbare Angebot?
    </h3>
    <ul>
      <li>🎉 6 Monate für nur 15€/Monat tanzen</li>
      <li>
        🎉 Gültig für alle Kategorien mit Abo (Kinder / Erwachsene / Senioren)
      </li>
      <li>🎉 Das Angebot ist gültig bis 30.06.2024</li>
    </ul>
    <div class="row">
      <div class="col-lg-4">
        <img
          src="./../../../assets/15-jahre-tanzschule-seidl.webp"
          alt="15 Jahre Tanzschule Seidl Amberg"
        />
      </div>
      <div class="col-lg-8">
        <h4 class="text-primary">
          15 Jahre Tanzschule Seidl Jubiläumsangebot - jetzt sichern!
        </h4>
        <h5 class="text-primary">
          18 monatige Mitgliedschaft - Trainiere 6 Monate für nur 15€ pro Monat!
        </h5>
        <form
          #contactForm="ngForm"
          class="row"
          (ngSubmit)="sendForm(contactForm)"
        >
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Dein Vorname</mat-label>
              <input
                matInput
                name="firstname"
                [(ngModel)]="contact.firstname"
                required
              />
              <mat-error>Vorname ist ein Pflichtfeld</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Dein Nachname</mat-label>
              <input
                matInput
                name="lastname"
                [(ngModel)]="contact.lastname"
                required
              />
              <mat-error>Nachname ist ein Pflichtfeld</mat-error>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Dein Kurs</mat-label>
            <mat-select
              name="contract"
              placeholder="Wähle dein Abo aus"
              [(ngModel)]="contact.contract"
            >
              @for(type of contract; track type) {
              <mat-option [value]="type">
                {{ type }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Dein Startdatum</mat-label>
            <mat-select
              name="startdate"
              placeholder="Wähle ab wann das Abo laufen soll"
              [(ngModel)]="contact.startdate"
            >
              @for(date of startdates; track date) {
              <mat-option [value]="date">
                {{ date }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine E-Mail Adresse</mat-label>
            <input
              matInput
              name="mail"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              type="email"
              [(ngModel)]="contact.mail"
              required
              #email="ngModel"
            />
            @if(email.touched) { @if(email.hasError('required')) {
            <mat-error>E-Mail Adresse ist ein Pflichtfeld</mat-error>
            } @if(email.hasError('pattern')) {
            <mat-error>Bitte gebe eine gültige E-Mail Adresse ein</mat-error>
            } }
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine Nachricht</mat-label>
            <textarea
              matInput
              name="message"
              [cdkTextareaAutosize]="true"
              [cdkAutosizeMinRows]="5"
              [(ngModel)]="contact.message"
            ></textarea>
          </mat-form-field>

          <mat-checkbox
            [color]="'primary'"
            [required]="true"
            [(ngModel)]="privacyCheck"
            #checkInput="ngModel"
            name="privacyCheck"
          >
            Ich akzeptiere die
            <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> und
            <a href="agb" target="_blank">AGBs</a>
          </mat-checkbox>
          <br /><br />
          <small>* Pflichtfeld</small><br /><br />
          <button
            type="submit"
            [disabled]="!contactForm.form.valid"
            mat-raised-button
            color="primary"
          >
            Absenden
          </button>
        </form>
        <br />
        <p>
          Deine Mitgliedschaft bei uns startet wahlweise ab 1.6.24 oder 1.7.24.
          <br />Preisanpassung: Der Beitrag ändert sich nach 6 Monaten auf den
          aktuellen Grundbetrag des jeweiligen Vertrages.
        </p>
      </div>
    </div>
  </div>
</div>
