import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './main/home/home.component';
import { CoursesComponent } from './main/courses/courses.component';
import { CoursesDetailComponent } from './main/courses/courses-detail/courses-detail.component';
import { EventsComponent } from './main/events/events.component';
import { CouponComponent } from './main/coupon/coupon.component';
import { ContactComponent } from './main/contact/contact.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { TeamComponent } from './main/about-us/team/team.component';
import { SingleTeamComponent } from './main/about-us/team/single-team/single-team.component';
import { PhilosophyComponent } from './main/about-us/philosophy/philosophy.component';
import { EducationComponent } from './main/about-us/education/education.component';
import { RoomsComponent } from './main/about-us/rooms/rooms.component';
import { ImprintComponent } from './main/legal/imprint/imprint.component';
import { PrivacyComponent } from './main/legal/privacy/privacy.component';
import { AgbComponent } from './main/legal/agb/agb.component';
import { HelloagainComponent } from './main/helloagain/helloagain.component';
import { AdventComponent } from './main/advent/advent.component';
import { TermsComponent } from './main/legal/terms/terms.component';
import { PrivatelessonsComponent } from './main/privatelessons/privatelessons.component';
import { LandingpagesComponent } from './main/landingpages/landingpages.component';
import { LandingpageComponent } from './main/landingpage/landingpage.component';
import { AnniversaryComponent } from './main/anniversary/anniversary.component';
import { CourseFeeComponent } from './main/course-fee/course-fee.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'kurse',
        component: CoursesComponent,
      },
      {
        path: 'kurse/:title/:id',
        component: CoursesComponent,
      },
      {
        path: 'kurse/details/:title/:typeId/:id',
        component: CoursesDetailComponent,
      },
      {
        path: 'events-tanzabende',
        component: EventsComponent,
      },
      {
        path: 'gutscheine',
        component: CouponComponent,
      },
      {
        path: 'kontakt',
        component: ContactComponent,
      },
      {
        path: 'ueber-uns',
        component: AboutUsComponent,
      },
      {
        path: 'ueber-uns/team',
        component: TeamComponent,
      },
      {
        path: 'ueber-uns/team/:name',
        component: SingleTeamComponent,
      },
      {
        path: 'ueber-uns/unsere-philosophie',
        component: PhilosophyComponent,
      },
      {
        path: 'ueber-uns/ausbildung',
        component: EducationComponent,
      },
      {
        path: 'ueber-uns/unsere-raeume',
        component: RoomsComponent,
      },
      {
        path: 'impressum',
        component: ImprintComponent,
      },
      {
        path: 'datenschutz',
        component: PrivacyComponent,
      },
      {
        path: 'agb',
        component: AgbComponent,
      },
      {
        path: 'teilnahmebedingungen',
        component: TermsComponent,
      },
      {
        path: 'helloagain',
        component: HelloagainComponent,
      },
      {
        path: 'adventskalender',
        component: AdventComponent,
      },
      {
        path: '15-jahre-tanzschule-seidl',
        component: AnniversaryComponent,
      },
      {
        path: 'privatstunden',
        component: PrivatelessonsComponent,
      },
      {
        path: 'kurshonorar',
        component: CourseFeeComponent,
      },
      {
        path: 'landingpage',
        component: LandingpageComponent,
      },
      {
        path: ':title',
        component: LandingpagesComponent,
      },
      {
        path: ':title/:subtitle',
        component: LandingpagesComponent,
      },
      {
        path: ':title/:subtitle/:id/:typeId',
        component: LandingpagesComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];
