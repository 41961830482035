<h2 mat-dialog-title>
  Anmeldung<button
    mat-icon-button
    class="close-button text-end"
    mat-dialog-close
  >
    <mat-icon class="close-icon" mat-dialog-close color="primary"
      >close</mat-icon
    >
  </button>
</h2>
<hr />
<mat-dialog-content class="mat-typography">
  @if(url && url !== '') {
  <iframe title="Anmeldung ADTV Tanzschule Seidl Amberg" [src]="url"></iframe>
  }
</mat-dialog-content>
