<app-small-header title="Impressum"></app-small-header>
<div id="content" class="content" role="main" bis_skin_checked="1">
  <div class="container">
    <h3>Angaben gemäß § 5 TMG:</h3>
    <p>ADTV Tanzschule Seidl GmbH</p>
    <h3>Postanschrift:</h3>
    <p>Philipp-Melanchthon-Str. 20<br />92224 Amberg</p>
    <h3>Kontakt:</h3>
    <p>Telefon: (0 96 21) 60 03 23<br />E-Mail: info&#64;tanzen-amberg.de</p>
    <h3>Vertreten durch:</h3>
    <p>Geschäftsführerin: Elisabeth Seidl</p>
    <h3>Eingetragen am</h3>
    <p>Amtsgericht Amberg<br />HRB 5976</p>
    <h3>Steuernummer</h3>
    <p>201/272/40109</p>
    <h3>Hinweise zur Website</h3>
    <h4>Information gemäß § 36 VSBG</h4>
    <p>
      Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
      alternative Streitbeilegung in Verbrauchersachen) erklärt der Betreiber
      dieser Website:
    </p>
    <p>
      Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h3>Hinweis</h3>
    <p>
      Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie
      online unter:&nbsp;<a href="https://ec.europa.eu/consumers/odr/"
        >https://ec.europa.eu/consumers/odr/</a
      >
    </p>
  </div>
</div>
