import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIReturnCourse, APIReturnTypes } from '../_models/nimbuscloud.model';
import { environment } from '../../environments/environment';
import { Event } from '../_models/event.model';

const BASE_URL = environment.nimbuscloudUrl;

@Injectable({
  providedIn: 'root',
})
export class NimbuscloudService {
  constructor(private http: HttpClient) {}

  getTypes(): Observable<APIReturnTypes> {
    return this.http.get<any>(BASE_URL + 'types') as Observable<APIReturnTypes>;
  }

  getCourses(typeId: string, levelId: string): Observable<APIReturnCourse> {
    return this.http.get<any>(
      BASE_URL + 'courses?typeId=' + typeId + '&levelId=' + levelId
    ) as Observable<APIReturnCourse>;
  }

  getEvents(): Observable<Event[]> {
    return this.http.get<any>(BASE_URL + 'events') as Observable<Event[]>;
  }
}
