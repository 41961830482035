import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Event, PaginationEvent } from '../_models/event.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private url = environment.url + 'events';
  constructor(private http: HttpClient) {}

  getAll(
    page = 0,
    size = 999999,
    title: string = ''
  ): Observable<PaginationEvent> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('title', title);
    return this.http.get(this.url, { params }) as Observable<PaginationEvent>;
  }

  getById(id: number, include: string): Observable<Event> {
    if (include) {
      include = '?' + include;
    }
    return this.http.get(this.url + '/' + id + include) as Observable<Event>;
  }
}
