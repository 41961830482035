import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursesBoxComponent } from './courses-box/courses-box.component';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';

@Component({
  selector: 'app-courses',
  standalone: true,
  imports: [CommonModule, CoursesBoxComponent, SmallHeaderComponent],
  templateUrl: './courses.component.html',
  styleUrl: './courses.component.scss',
})
export class CoursesComponent implements OnInit {
  public title: string = 'Kursübersicht';
  public id: string = '';

  constructor(route: ActivatedRoute, private meta: Meta) {
    route.paramMap.subscribe((params) => {
      let id = params.get('id');
      if (id && id !== '') {
        this.id = id;
      }
      let title = params.get('title');
      if (title && title !== '') {
        this.title = title;
      }
    });
  }

  ngOnInit(): void {
    this.meta.updateTag({
      name: 'description',
      content:
        this.title +
        ' - ADTV Tanzschule Seidl Amberg - Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunde, Discofox, Salsa, Hip Hop, Ballett, Zumba, Kinder-Teenie-Tanzen, Linedance uvm',
    });
  }
}
