<h2 mat-dialog-title>
  Privatstunde anfragen
  <button
    tabindex="-1"
    mat-icon-button
    class="close-button text-end"
    mat-dialog-close
  >
    <mat-icon class="close-icon" mat-dialog-close color="primary"
      >close</mat-icon
    >
  </button>
</h2>
<hr />
<form #contactForm="ngForm" (ngSubmit)="sendForm(contactForm)">
  <mat-dialog-content class="mat-typography">
    <p>
      Du möchtest eine Privatstunde bei uns buchen? Fülle einfach das Formular
      aus und wir melden uns bei dir.
    </p>
    <p>
      <strong>Ausgewählter Lehrer: {{ teacher.name }}</strong>
    </p>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>Dein Name</mat-label>
      <input
        tabindex="0"
        matInput
        name="name"
        [(ngModel)]="contact.name"
        required
      />
      <mat-error>Name ist ein Pflichtfeld</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Deine E-Mail Adresse</mat-label>
      <input
        matInput
        name="email"
        type="email"
        [(ngModel)]="contact.mail"
        required
      />
      <mat-error>E-Mail Adresse ist ein Pflichtfeld</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Deine Telefonnummer</mat-label>
      <input matInput name="phone" type="tel" [(ngModel)]="contact.tel" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Rückrufzeit (falls gewünscht)</mat-label>
      <input
        matInput
        name="callbackTime"
        type="text"
        [(ngModel)]="contact.callbackTime"
      />
    </mat-form-field>
    @if(teacher.single || teacher.couple || teacher.group) {
    <mat-label>Details</mat-label><br />
    <mat-radio-group
      name="courseType"
      [(ngModel)]="contact.courseType"
      aria-label="Select an option"
      color="primary"
    >
      @if(teacher.couple) {
      <mat-radio-button value="Zu zweit">Wir sind zu Zweit</mat-radio-button>
      } @if(teacher.single) {
      <mat-radio-button value="Ohne Tanzpartner">
        Ich komme ohne Tanzpartner*in
      </mat-radio-button>
      } @if(teacher.group) {
      <mat-radio-button value="Mehrere Paare">
        Wir sind mehrere Paare
      </mat-radio-button>
      }
    </mat-radio-group>
    <br /><br />
    }
    <mat-form-field appearance="fill">
      <mat-label>Gewünschte Unterrichtstage und Zeiten</mat-label>
      <input
        matInput
        name="daysAndTimes"
        type="text"
        [(ngModel)]="contact.daysAndTimes"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Das möchten wir lernen</mat-label>
      <textarea
        matInput
        name="message"
        type="text"
        [cdkTextareaAutosize]="true"
        [cdkAutosizeMinRows]="5"
        [(ngModel)]="contact.message"
      ></textarea>
    </mat-form-field>
    <mat-checkbox
      name="privacyCheck"
      [(ngModel)]="privacyCheck"
      [required]="true"
      color="primary"
      >Ich akzeptiere die
      <a href="https://tanzen-amberg.de/agb"
        >Allgemeinen Geschäftsbedingungen</a
      >
      und
      <a href="https://tanzen-amberg.de/datenschutz">Datenschutzerklärung</a
      >.</mat-checkbox
    >
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      [disabled]="contactForm.invalid || !privacyCheck"
      mat-fab
      extended
      color="primary"
    >
      <mat-icon>send</mat-icon>
      Absenden
    </button></mat-dialog-actions
  >
</form>
