<app-small-header
  title="Ausbildung"
  [breadcrumb]="breadCrumb"
></app-small-header>

<div class="content">
  <div class="container">
    <h2>
      Du suchst eine Ausbildung, bei der du dein Hobby zum Beruf machen kannst?
      Du hast Freude am Umgang mit Menschen und am Tanzen? Dann bist du hier
      genau richtig!
    </h2>
    <div class="spacer-container">
      <div class="spacer"></div>
    </div>
    <p>
      Wir bieten dir eine Ausbildungsstelle zum/r ADTV-Tanzlehrer/in. Als
      erfahrene Ausbildungsstätte begleiten wir dich als Mitglied unserer
      Tanzschul-Familie auf deinem Weg.
    </p>
    <p>
      Die Ausbildung ist in einen theoretischen und praktischen Teil
      untergliedert. Im in der Theorie, die ca 20% deiner Ausbildung ausmacht,
      lernst du selbst das Technische Tanzen mit allem was dazu gehört.<br />
      In der Praktischen Ausbildung, die die restliche Ausbildung ausmacht,
      lernst du Schritt für Schritt wie du dieses Wissen, je nach Kursstufe, an
      die Kunden vermittelst. Wir stehen dir als Team dabei natürlich mit Rat
      und Tat zur Seite.<br />
      Begleitend zur Ausbildung besuchst du Seminare für Methodik,
      Unterrichtstheorie, Animation, und vieles mehr.
    </p>
    <p><br /></p>
    <p>
      <strong
        >Dazu hast du die Möglichkeit dich in verschiedenen Fachrichtungen zu
        spezialisieren, z.B in:</strong
      >
    </p>

    <ul>
      <li>Kindertanz</li>
      <li>Seniorentanz</li>
      <li>Salsa/Latino</li>
      <li>Boogie Woogie</li>
      <li>HipHop</li>
      <li>Videoclipdance</li>
      <li>u.v.m</li>
    </ul>

    <p>
      Der Beruf des Tanzlehrers ist sehr vielseitig und kein Tag gleicht dem
      anderen. Neben dem Assistieren und Unterrichten, wird es Tage geben, an
      denen du mehr hinter der Bar oder am DJ-Pult stehst oder auch im Büro
      eingesetzt wirst. Selbst in Lockdown-Zeiten können wir dir, durch
      Online-Unterricht für unsere Kunden, einen sicheren Ausbildungsplatz
      bieten.
    </p>
    <h2>Was du mitbringen solltest:</h2>
    <div class="spacer-container">
      <div class="spacer"></div>
    </div>
    <ul>
      <li>Freude am Umgang mit Menschen</li>
      <li>Interesse am pädagogischen Unterrichten</li>
      <li>Spaß an sportlicher/körperlicher Betätigung</li>
      <li>Rhythmusgefühl</li>
      <li>Geduld</li>
      <li>Bereitschaft zu lernen und selbstständig zu arbeiten</li>
      <li>Verlässlichkeit und Engagment</li>
    </ul>
    <h2>Was wir dir als ADTV-Tanzschule bieten:</h2>
    <div class="spacer-container">
      <div class="spacer"></div>
    </div>
    <ul>
      <li>Familiärer/Freundschaftlicher Umgang</li>
      <li>Kreatives, junges Team</li>
      <li>Unterstützung bei ausbildungsrelevanten Themen</li>
      <li>Arbeiten mit verschiedenen Altersgruppen (Kinder bis Senioren)</li>
      <li>Notfallplan für Lockdown (Online-Unterricht)</li>
      <li>Wahl von interessanten Weiter- und Zusatz-Ausbildungen</li>
      <li>Umsetzen deines kreativen Inputs</li>
    </ul>
    <h2>Haben wir dein Interesse geweckt?</h2>
    <div class="spacer-container">
      <div class="spacer"></div>
    </div>
    <p class="center">
      Schick uns doch gern deine Bewerbung an
      <a href="mailto:info@tanzen-amberg.de">info&#64;tanzen-amberg.de</a>
    </p>
    <p><br /></p>
    <div class="row form">
      <div class="col-md-4">
        <p>
          Du bist dir noch nicht sicher ob die Ausbildung wirklich was für dich
          ist? In einem Praktikum kannst du gern mal in den Tanzschul-Alltag
          reinschnuppern. Kontaktiere uns hierfür gern über das Kontaktformular
          oder rufe kurz an.
        </p>
        <p><br /></p>
        <div class="row">
          <div class="col-2">
            <mat-icon>contact_mail</mat-icon>
          </div>
          <div class="col-10">
            <strong>Telefon</strong><br />
            (0 96 21) 60 03 23<br /><br />
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <form #contactForm="ngForm" (ngSubmit)="sendForm(contactForm)">
          <mat-form-field appearance="fill">
            <mat-label>Dein Name</mat-label>
            <input matInput name="name" [(ngModel)]="contact.name" required />
            <mat-error>Name ist ein Pflichtfeld</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine E-Mail Adresse</mat-label>
            <input
              matInput
              name="mail"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              type="email"
              [(ngModel)]="contact.mail"
              required
              #email="ngModel"
            />
            <mat-error *ngIf="email.touched && email.hasError('required')"
              >E-Mail Adresse ist ein Pflichtfeld
            </mat-error>
            <mat-error *ngIf="email.touched && email.hasError('pattern')"
              >Bitte gebe eine gültige E-Mail Adresse ein</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine Telefonnummer</mat-label>
            <input matInput name="tel" type="tel" [(ngModel)]="contact.tel" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Betreff</mat-label>
            <mat-select name="subject" [(ngModel)]="contact.subject" required>
              <mat-option value="Ausbildung">Ausbildung</mat-option>
              <mat-option value="Praktikum">Praktikum</mat-option>
            </mat-select>
            <mat-error>Betreff ist ein Pflichtfeld</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine Nachricht (optional)</mat-label>
            <textarea
              matInput
              name="message"
              [cdkTextareaAutosize]="true"
              [cdkAutosizeMinRows]="10"
              [(ngModel)]="contact.message"
            ></textarea></mat-form-field
          ><small>* Pflichtfeld</small><br /><br />
          <button
            type="submit"
            [disabled]="!contactForm.form.valid"
            mat-raised-button
            color="primary"
          >
            Absenden
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
