<div class="header" [ngStyle]="{ 'background-image': url }">
  <div class="header-image"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 header-text">
        <h2>Herzlich Willkommen auf den Seiten der</h2>
        <h1>ADTV Tanzschule Seidl!</h1>
        <p>{{ title }} {{ subtitle ? " / " + subtitle : "" }}</p>
      </div>
    </div>
  </div>
</div>
