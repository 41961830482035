import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CoursesBoxComponent } from '../courses/courses-box/courses-box.component';
import { Alert } from '../../_models/alert.model';
import { Title } from '@angular/platform-browser';
import { AlertService } from '../../_services/alert.service';
import { IAlbum, Lightbox, LightboxModule } from 'ngx-lightbox';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    MatButtonModule,
    CoursesBoxComponent,
    LightboxModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  alerts: Alert[] = [];

  public album: IAlbum[] = [];

  constructor(
    private titleService: Title,
    private lightbox: Lightbox,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('ADTV Tanzschule Seidl Amberg | Tanzen Amberg');
    this.loadAlerts();
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  //Load alerts
  loadAlerts() {
    this.alertService.getAll().subscribe((alerts) => {
      if (alerts.rows && alerts.rows.length > 0) {
        this.alerts = alerts.rows;
        this.loadGallery();
      }
    });
  }

  loadGallery() {
    this.alerts.forEach((alert) => {
      if (alert.imagePath) {
        this.album.push({
          src: this.replaceImageUrl(alert.imagePath),
          caption: alert.title,
          thumb: this.replaceImageUrl(alert.imagePath),
          downloadUrl: this.replaceImageUrl(alert.imagePath),
        });
      } else {
        this.album.push({
          src: 'assets/images/placeholder.png',
          caption: alert.title,
          thumb: 'assets/images/placeholder.png',
          downloadUrl: 'assets/images/placeholder.png',
        });
      }
    });
  }

  replaceImageUrl(path: string) {
    return environment.imageUrl + path;
  }
}
