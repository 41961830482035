<app-small-header title="Teilnahmebedingungen"></app-small-header>
<div id="content" class="content" role="main" bis_skin_checked="1">
  <div class="container">
    <div>
      <h2>Gewinnspiel Disclaimer / Teilnahmebedingungen</h2>

      <h6>Stand 25.11.2023</h6>
      <h3>§ 1 Allgemeines</h3>
      <p>
        Eine Teilnahme am Gewinnspiel ist nur unter Einbeziehung dieser
        Teilnahmebedingungen möglich. Die Aktion oder das Gewinnspiel stehen in
        keinerlei Verbindung zu Facebook und werden in keinster Weise von
        Facebook gesponsert, unterstützt oder organisiert! Betreiber und
        Veranstalter des jeweiligen Gewinnspiels ist die im Impressum angegebene
        Firma, im Regelfall ein Unternehmen der Tanzbranche (im Folgenden
        Veranstalter genannt), der für die von ihm beworbenen Gewinnspiele die
        alleinige Haftung und Verantwortung trägt.
      </p>
      <h3>§ 2 Teilnahmevoraussetzungen</h3>
      <p>
        Teilnehmen können alle natürlichen und geschäftsfähigen Personen mit
        einem Mindestalter von 18 Jahren, die nicht weiter als 25 km vom
        jeweiligen Veranstalter des Gewinnspiels (Tanzsschule) entfernt wohnen.
        Mitarbeiter/innen des Veranstalters und dessen Verwandte sind von der
        Teilnahme ausgeschlossen. Der/Die Teilnehmer/in erklärt sich mit der
        Teilnahme damit einverstanden, dass im Gewinnfalle sein/ihr Name
        und/oder Bild im Internet veröffentlicht wird. Die Teilnahme ist, wenn
        in der Gewinnspielbeschreibung genannt, nur innerhalb der in der
        Gewinnspielbeschreibung genannten Frist möglich. Der Rechtsweg ist
        ausgeschlossen.
      </p>
      <h3>§ 3 Gewinn</h3>
      <p>
        Der Preis wird, wie im Gewinnspiel ausgeschrieben, vergeben. Bei
        mehreren richtigen Einsendungen entscheidet das Los unter Ausschluss des
        Rechtsweges. Eine Änderung oder Barauszahlung des Preises ist
        ausgeschlossen. Geldbeträge bzw. die Wertigkeit eines bestimmten Gewinns
        können nicht in bar ausgezahlt, wohl aber in Form einer Mitgliedschaft
        verrechnet werden. Der/Die Gewinner/in wird telefonisch oder via
        Facebook von uns benachrichtigt. Meldet sich der/die Gewinner/in nicht
        binnen 1 Woche auf die Gewinnmitteilung, verfällt der Gewinn ersatzlos.
      </p>
      <h3>§ 4 Haftung</h3>
      <p>
        Schadenersatzansprüche gegenüber den Veranstalter, die im Zusammenhang
        mit dem Gewinnspiel stehen, sind - innerhalb des gesetzlich zulässigen -
        unabhängig vom Rechtsgrund ausgeschlossen, es sei denn, der Veranstalter
        hätte vorsätzlich oder grob fahrlässig gesetzliche Pflichten verletzt.
        Ferner haftet der Veranstalter nicht für Schäden aus der
        Beeinträchtigung der Verfügbarkeit der Gewinnspiel-Internetseite bei
        nicht beeinflussbaren technischen Störungen und Ereignissen höherer
        Gewalt, sowie Angriffen Dritter gegen die Gewinnspiel- Internetseite.
        Der Veranstalter wird jedoch alles unternehmen, um die Zuverlässigkeit
        und Funktionsfähigkeit der Gewinnspiel- Internetseite sicherzustellen.
        Weiterhin übernimmt der Veranstalter keine Garantie dafür, dass die
        Gewinnspiel-Internetseite auf dem jeweiligen Teilnehmerrechner
        ordnungsgemäß funktionsfähig ist. Darüber hinaus trägt der Veranstalter
        keinerlei Verantwortung oder Haftung für den Fall von rechtwidrigen,
        beleidigenden oder falschen Tatsachenbehauptungen in Form von Kommentare
        durch Nutzer des jeweiligen Facebook Gewinnspiel-Posts/-Anzeige.
      </p>
      <h3>§ 5 Datenschutz</h3>
      <p>
        Der Betreiber gewährt im Rahmen des Gewinnspiels den größtmöglichen
        datenschutzrechtlichen Standard und beachtet alle diesbezüglich
        einschlägigen gesetzlichen Bestimmungen. Der Betreiber wird keine
        personenbezogenen Daten an Dritte, ausgenommen an das jeweilige Studio
        an dessen Gewinnspiel teilgenommen wurde, weiterleiten oder Adressdaten
        verkaufen. Der Veranstalter speichert die personenbezogenen Daten der
        jeweiligen Teilnehmer/ in ausschließlich zum Zwecke des Gewinnspiels und
        der Möglichkeiten der Werbung über E-Mail, Telefon oder Postweg. Der/Die
        Teilnehmer/in erklärt hiermit ausdrücklich sein/ihr Einverständnis mit
        der Speicherung und Verwendung der mitgeteilten personenbezogenen Daten
        zu dem oben genannten Zweck.
      </p>
      <h3>§ 6 Salvatorische Klausel</h3>
      <p>
        Sollten einzelne Bestimmungen dieser Teilnahmebedingungen unwirksam sein
        oder eine Regelungslücke bestehen, berührt dies die Wirksamkeit der
        übrigen Bestimmungen nicht. An die Stelle der unwirksamen oder fehlenden
        Bestimmungen tritt eine Bestimmung, welche dem Vertragszweck und den
        gesetzlichen Bestimmungen am nächsten kommt.
      </p>
      <h3>§ 7 Gerichtsstand / anwendbares Recht</h3>
      <p>
        Bei Streitigkeiten gilt ausschließlich deutsches Recht. Als
        Gerichtsstand wird, soweit gesetzlich zulässig, der Sitz des
        Veranstalter vereinbart. Soweit der/die Teilnehmer/in keinen allgemeinen
        Gerichtsstand in Deutschland hat, oder nach der Teilnahme den Wohnsitz
        ins Ausland verlegt, wird der Sitz des Der Veranstalters ebenso als
        Gerichtsstand vereinbart.
      </p>
      <h3>§ 8 Auswahlverfahren</h3>
      <p>
        Die Gewinner werden per Zufall ausgewählt. Der Rechtsweg ist auch hier
        selbstverständlich ausgeschlossen.
      </p>
      <h3>§ 9 Kontakt</h3>
      <p>
        Bei Fragen in Bezug auf die Durchführung dieses Gewinnspiels und der
        Verarbeitung und Nutzung von persönlichen Daten wenden Sie sich bitte an
        den Veranstalter.
      </p>
      <h3>§ 10 Datenschutz</h3>
      <p>
        Sie erhalten explizite Informationen über unsere Datenschutzbestimmungen
        auf der Gewinnspielseite oder einer Angebotsseite unten unter
        Datenschutz.
      </p>
    </div>
  </div>
</div>
