<app-small-header
  [title]="member?.name || ''"
  [breadcrumb]="breadCrumb"
></app-small-header>
@defer { @if(member) {
<div class="content">
  <div class="background">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="imgCol">
            <img
              [ngSrc]="imagePath + member.imagePath"
              fill
              [priority]="true"
              sizes="100vw"
              class="card-img-top"
              [alt]="member.name + ' - ADTV Tanzschule Seidl Amberg'"
              (mouseover)="member.currentImg = member.imageHoverPath"
              (mouseout)="member.currentImg = member.imagePath"
              [hidden]="member.currentImg"
            />
            @if(member.currentImg && member.currentImg !== ''){
            <img
              [src]="imagePath + member.currentImg"
              fill
              class="card-img-top"
              [alt]="member.name + ' - ADTV Tanzschule Seidl Amberg'"
              (mouseover)="member.currentImg = member.imageHoverPath"
              (mouseout)="member.currentImg = member.imagePath"
              [hidden]="!member.currentImg"
            />
            }
          </div>
        </div>
        <div class="col-md-9">
          <div [innerHtml]="member.description"></div>
          @if(member && job && job.length > 0){
          <div class="info">
            <ul>
              @for(info of job; track $index) {
              <li>{{ info }}</li>
              }
            </ul>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
}}
