import { Component, afterNextRender } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import { FormsModule, NgForm } from '@angular/forms';
import { FormData } from '../../_models/mail.model';
import { MailService } from '../../_services/mail.service';
import {
  faFacebookF,
  faInstagram,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    SmallHeaderComponent,
    FontAwesomeModule,
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent {
  public contact = {
    name: '',
    mail: '',
    tel: '',
    company: '',
    message: '',
  };

  isGoogleMaps = false;
  fabFacebook = faFacebookF;
  fabInstagram = faInstagram;
  fabTiktok = faTiktok;

  constructor(private mail: MailService, private snackbar: MatSnackBar) {
    afterNextRender(() => {
      if (localStorage.getItem('isGoogleMaps') === 'true') {
        this.isGoogleMaps = true;
      } else {
        this.isGoogleMaps = false;
      }
    });
  }

  sendForm(contactForm: NgForm) {
    const formData: FormData = {
      mail: environment.mailTo,
      subject: 'Kontaktanfrage von: ' + this.contact.name,
      text: `Name: ${this.contact.name}\nE-Mail: ${this.contact.mail}\nTelefon: ${this.contact.tel}\nFirma: ${this.contact.company}\n\nNachricht:\n${this.contact.message}`,
      html: `<table><tr><td>Name: </td><td>${
        this.contact.name
      }</td></tr><tr><td>E-Mail: </td><td>${
        this.contact.mail
      }</td></tr><tr><td>Telefon: </td><td>${
        this.contact.tel
      }</td></tr><tr><td>Firma: </td><td>${
        this.contact.company
      }</td></tr></table><br><strong>Nachricht:</strong><br>${this.contact.message.replace(
        /\n/g,
        '<br />'
      )}`,
    };

    this.mail.sendForm(formData).subscribe({
      next: (value) => {
        this.snackbar.open('Erfolgreich versendet!', '', {
          duration: 3000,
          panelClass: 'bg-success',
        });
        contactForm.resetForm();
      },
      error: (error) => {
        console.log(error);
        this.snackbar.open('Fehler beim Senden!', '', {
          duration: 3000,
          panelClass: 'bg-danger',
        });
      },
      complete: () => console.log('Complete'),
    });
  }

  allowGoogleMap() {
    this.isGoogleMaps = true;
    localStorage.setItem('isGoogleMaps', 'true');
  }
}
