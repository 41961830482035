export const Landingpages = [
  {
    title: 'Erwachsene',
    alias: 'erwachsene',
    image: '',
    subitems: [
      {
        title: 'Paartanz',
        alias: 'paartanz',
        image:
          'https://images.unsplash.com/photo-1531747056595-07f6cbbe10ad?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        subitems: [
          {
            title: 'Crashkurs',
            id: 6,
            type: 16,
          },
          {
            title: 'Einsteiger',
            id: 6,
            type: 79,
          },
        ],
      },
      {
        title: 'Solotanz',
        alias: 'solotanz',
        image:
          'https://images.unsplash.com/photo-1519689157479-930721ed8836?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        subitems: [],
      },
      {
        title: 'Fitness',
        alias: 'fitness',
        image:
          'https://images.unsplash.com/photo-1527933053326-89d1746b76b9?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        subitems: [],
      },
    ],
  },
  {
    title: 'Kinder & Jugendliche',
    alias: 'kinder-jugendliche',
    subitems: [
      {
        title: 'Kinder bis 6 Jahre',
        alias: 'kinder-6-jahre',
        subitems: [],
      },
      {
        title: 'Jugendliche ab 7 Jahre',
        alias: 'jugendliche',
        subitems: [],
      },
      {
        title: 'Schulkurse',
        alias: 'schulkurse',
        subitems: [],
      },
    ],
  },
  {
    title: 'Senioren',
    alias: 'senioren',
    subitems: [],
  },
];
