<app-small-header title="Allgemeine Geschäftsbedingungen"></app-small-header>
<div id="content" class="content container" role="main">
  <h2>Buchung</h2>
  <p>
    Nach Eingang der Anmeldung ist Ihre Buchung verbindlich und Sie sind zur
    Zahlung des Kurs-Beitrags bis zur Kündigung verpflichtet. Änderungen der
    Termine und Angebote bleiben vorbehalten.
  </p>
  <h2>Datenschutz und Änderungen</h2>
  <p>
    Alle umseitigen Angaben zu Ihrer Person (oder Ihres Kindes) werden lediglich
    für die Mitgliederverwaltung verwendet. Sollten sich Name, Adresse oder
    Bankverbindung ändern, sind diese Änderungen der ADTV Tanzschule Seidl GmbH
    unverzüglich über die Dance Community (Kundencenter), E-Mail oder Telefon
    mitzuteilen.
  </p>
  <h2>Anmeldegebühr / Bearbeitungsgebühr</h2>
  <p>
    Bei der Erstanmeldung in der ADTV Tanzschule Seidl GmbH, fallen keine
    gesonderten Gebühren an. Sollten sie bereits Kunde der Tanzschule gewesen
    sein, berechnen wir bei Neustart eine Bearbeitungsgebühr von 20 Euro pro
    Person. Ein Wechsel in einen anderen Kurs oder Vertrag ist für aktive Kunden
    jederzeit kostenlos möglich.
  </p>
  <h2>Kursgebühr</h2>
  <p>
    Das Honorar wird jeweils zum 1. des Monats im Voraus fällig und mittels SEPA
    Lastschriftverfahren eingezogen. Es wird auch dann fällig, wenn der
    Teilnehmer nicht am Unterricht teilnimmt. Bei nicht eingelösten
    Lastschriften entsteht zusätzlich eine Rücklastschriftgebühr von 5 Euro.
  </p>
  <p>
    Preiserhöhungen in regelmäßigen Abständen bleiben vorbehalten und werden dem
    Teilnehmer oder Erziehungsberechtigten rechtzeitig im Voraus per Mail und
    Kontoauszug mitgeteilt.
  </p>
  <h2>SEPA Lastschriftmandat (Gläubiger ID: DE26ZZZ00000919934)</h2>
  <p>
    Mit Ihrer Unterschrift erteilen Sie der ADTV Tanzschule Seidl GmbH bis zur
    Kündigung Ihres Vertrags die Bankeinzugsvollmacht des genannten Betrags.
    Ferner weisen sie ihr Kreditinstitut an, die von der ADTV Tanzschule Seidl
    GmbH auf mein Konto gezogenen Lastschriften einzulösen.
  </p>
  <h2>Kündigung</h2>
  <p>
    Alle Verträge verlängern sich automatisch bis zum Eingang der Kündigung!<br />
    Die Kündigungsfrist beträgt einen Monat zum Monatsende und muss schriftlich
    per E-Mail oder Post erfolgen.
  </p>

  <h2>Mindestlaufzeit und Vertragspausen</h2>
  <p>
    Alle Verträge der ADTV Tanzschule Seidl GmbH haben eine Mindestlaufzeit von
    nur 3 Monaten und verlängert sich danach automatisch immer um einen weiteren
    Monat.
  </p>
  <p>
    Sollte bei Tanzzeitverträgen eine höhere Laufzeit abgeschlossen worden sein,
    verlängern sich auch diese Verträge nach Ablauf der vereinbarten Zeit,
    jeweils um einen weiteren Monat. Eine vorzeitige Kündigung ist möglich,
    allerdings mit einer Nachberechnung behaftet.
  </p>
  <p>
    Eine Pausierung des Vertrages ist grundsätzlich nicht möglich und obliegt im
    Einzelfall der Tanzschule falls ein ärztliches Attest vorgelegt wird.
  </p>
  <h2>Betriebsferien und Schulferien</h2>
  <p>
    In den Betriebsferien der ADTV Tanzschule Seidl GmbH findet kein Unterricht
    statt.
  </p>
  <p>
    Alle Kinder- und Schülerkurse (Zwergerltanz / Kindertanz / Ballett / HipHop
    / Breakdance / Contemporary / Streetdance) sowie auch unsere Tanzkreise
    entfallen während der kompletten bayerischen Schulferien.
  </p>
  <p>Der Bankeinzug ist hiervon nicht beeinflusst.</p>
  <h2>Sonstige Anmerkungen:</h2>
  <ul>
    <li>
      Die ADTV Tanzschule Seidl GmbH gewährt den Teilnehmern während der
      offiziellen Öffnungszeiten, gegen das vereinbarte Honorar, die Nutzung der
      gebuchten Leistung. Im Sonderfall kann diese auch online durchgeführt
      werden. Der Bankeinzug wird hiervon nicht beeinträchtigt
    </li>
    <li>
      Die Teilnahme am Kurs findet auf eigene Gefahr statt. Für Verletzungen
      oder evtl. körperliche Schäden jeglicher Art übernimmt die ADTV Tanzschule
      Seidl GmbH keinerlei Haftung.
    </li>
    <li>
      Film- und Fotoaufnahmen der Teilnehmer sind nur mit vorheriger Genehmigung
      erlaubt.
    </li>
    <li>
      Die Kursgebühr ist ein Jahreshonorar, welches in 12 monatliche Raten
      aufgeteilt wird (siehe o. g. Beitrag) und per SEPA-Lastschriftverfahren
      beglichen wird. Die Abbuchung findet dadurch auch in den Unterrichtsfreien
      Zeiten statt!
    </li>
  </ul>
</div>
<!-- <div id="content" class="content" role="main" bis_skin_checked="1">
  <div class="container">
    <h2>Tanzzeitvertrag</h2>
    <p>
      Die Anmeldung erfolgt ab dem angegebenen Kursbeginn.<br />Eine Kopie
      dieser Anmeldung erhalten Sie von der ADTV Tanzschule Seidl GmbH.<br />Nach
      Eingang der Anmeldung, sind Sie verbindlich angemeldet und zur Zahlung des
      Kurs-Beitrags verpflichtet. Mit Ihrer Unterschrift stimmen Sie den AGBs
      der ADTV Tanzschule Seidl GmbH zu und erteilen der ADTV Tanzschule Seidl
      GmbH bis zur Kündigung Ihres Vertrags die Bankeinzugsvollmacht des
      genannten Betrags. Alle umseitigen Angaben zu Ihrer Person (oder Ihres
      Kindes) werden lediglich für die Mitgliederverwaltung verwendet.
    </p>
    <p>
      <strong
        >Die Kündigungsfrist des Tanz-Zeit Vertrags (DTA) beträgt&nbsp;einen
        Monat zum Monatsende&nbsp;und
        muss&nbsp;schriftlich&nbsp;erfolgen.</strong
      >
    </p>
    <ul>
      <li>
        <strong>Buchung</strong><br />Diese Buchung ist verbindlich. Änderungen
        der Termine und Angebote bleiben vorbehalten.
      </li>
      <li>
        <strong>Kursgebühr</strong><br />Das Honorar wird jeweils zum 1. des
        Monats im Voraus fällig und mittels SEPA Lastschriftverfahren
        eingezogen. Es wird auch dann fällig, wenn der Teilnehmer nicht am
        Unterricht teilnimmt. Bei nicht eingelösten Lastschriften sind die dabei
        entstandenen Bankgebühren zu erstatten.
      </li>
      <li>
        <strong>Änderungen</strong><br />Sollten sich Name, Adresse oder
        Bankverbindung des Teilnehmers ändern, sin diese Änderungen der ADTV
        Tanzschule Seidl GmbH unverzüglich mitzuteilen.
      </li>
      <li>
        <strong>Vertragsverlängerung (Tanz-Zeit DTA)</strong><br />Ihr Vertrag
        verlängert sich automatisch um die vereinbarte Buchungsdauer. Ein
        Wechsel in eine günstigere Tanz-Zeit ist zum Folgemonat möglich. Eine
        Abmeldung muss schriftlich erfolgen, die dafür berechnete
        Kündigungsfrist beträgt einen Monat zum Monatsende. Bei vorzeitiger
        Kündigung wird der Differenzbetrag zum tatsächlichen Tanz-Zeit angesetzt
        und nachberechnet.
      </li>
      <li>
        <strong>Sonstiges</strong><br />Die ADTV Tanzschule Seidl GmbH gewährt
        den Teilnehmern während der offiziellen Öffnungszeiten gegen das
        vereinbarte Honorar die Nutzung der gebuchten Leistung.
      </li>
      <li>
        <strong
          >SEPA Lastschriftmandat (Gläubiger ID: DE 26ZZZ00000919934)</strong
        ><br />Hiermit ermächtige ich die ADTV Tanzschule Seidl GmbH Zahlungen
        von meinem Konto mittels Lastschrift einzuziehen. Ferner weise ich mein
        Kreditinstitut an, die von der ADTV Tanzschule Seidl GmbH auf mein Konto
        gezogenen Lastschriften einzulösen. Hinweis: Ich habe die Möglichkeit
        innerhalb von 8 Wochen ab dem Tag der Belastungsanzeige zu verlangen,
        dass der belastete Betrag erstattet wird. Dabei gelten die Bedingungen
        die mit meinem Kreditinstitut vereinbart wurden.
      </li>
    </ul>
    <h2>Medaillen Kurs Plus</h2>
    <p>
      Der Unterricht findet 36x pro Jahr, einmal wöchentlich am o. g. Wochentag
      und zur o. g. Uhrzeit statt. Ein Wechsel in einen anderen Medaillen Kurs
      Plus für einzelne Stunden ist nicht möglich. In den Schulferien findet
      kein Unterricht statt. Der Bankeinzug ist hiervon nicht beeinflusst.Jede
      Unterrichtseinheit beinhaltet 50 Minuten Unterricht und 25 Minuten freies
      Tanzen.Das Jahreshonorar beträgt 360€ pro Person und wird in 12
      monatlichen Raten i. H. v. 30€ pro Person per SEPA Lastschriftverfahren
      beglichen. Rückbuchungen werden mit 5€ Gebühr belastet.
    </p>
    <h2>Medaillen Kurs Plus Flatrate</h2>
    <p>
      Der Medaillen-Kurs findet 36x pro Jahr, einmal wöchentlich am o. g.
      Wochentag und zur o. g. Uhrzeit statt. Ein Wechsel in einen anderen
      Medaillen Kurs Plus für einzelne Stunden ist nicht möglich. Die Flatrate
      beinhaltet zusätzlich den Besuch von sämtlichen
      Tanzkurs-Unterrichtsstunden des WTP2 und die die Kurse des DTA von Bronze
      bis Goldstar. In den Schulferien findet kein Medaillen Kurs Plus
      Unterricht, bzw. nur eingeschränkter Tanzkurs Unterricht statt. Der
      Bankeinzug ist hiervon nicht beeinflusst. Jede Unterrichtseinheit (DTA)
      beinhaltet 75 Minuten Unterricht oder Medaillen Kurs Plus 50 Minuten
      Unterricht und 25 Minuten freies Tanzen. Das Jahreshonorar beträgt 408€
      pro Person und wird in 12 monatlichen Raten i. H. v. 34€ pro Person per
      SEPA Lastschriftverfahren beglichen. Rückbuchungen werden mit 5€ Gebühr
      belastet
    </p>
    <p>
      <strong
        >Die Kündigungsfrist des Medaillen Kurs Plus / der Medaillen Kurs Plus
        Flatrate beträgt&nbsp;<u>drei Monate zum Monatsende</u>&nbsp;und
        muss&nbsp;<u>schriftlich</u>&nbsp;erfolgen.</strong
      >
    </p>
    <h2>1 x pro Woche – Vertrag</h2>
    <p>
      Die Anmeldung erfolgt ab dem angegebenen Kursbeginn.<br />Eine Kopie
      dieser Anmeldung erhalten Sie von der ADTV Tanzschule Seidl.<br />Nach
      Eingang der Anmeldung, sind Sie verbindlich angemeldet und zur Zahlung des
      Kurs-Beitrags verpflichtet.<br />Mit Ihrer Unterschrift stimmen Sie den
      AGBs zu.<br />Mit Ihrer Unterschrift erteilen Sie der ADTV Tanzschule
      Seidl bis zur Kündigung Ihres Vertrags die Bankeinzugsvollmacht des
      genannten Betrags. Alle umseitigen Angaben zu Ihrer Person (oder Ihres
      Kindes) werden lediglich für die Mitgliederverwaltung verwendet.&nbsp;
    </p>
    <p>
      <strong
        >Die Kündigungsfrist beträgt&nbsp;<u>einen Monat zum Monatsende</u
        >&nbsp;und muss&nbsp;<u>schriftlich</u>&nbsp;erfolgen.</strong
      >
    </p>
    <h2>
      Hip Hop / Kinder-Teenie Tanzen / West Coast Swing Club / Disco Fox Club /
      Street Dance / Break Dance / Ballett Kinder – 60 Minuten – 24€ mtl.
    </h2>
    <p>
      Der Unterricht findet 36x pro Jahr, einmal wöchentlich statt. Jede
      Unterrichtseinheit umfasst 60 Minuten incl. einer kleinen Pause. Der
      Vertrag beinhaltet eine Unterrichtseinheit pro Woche. Die Federführung für
      die Unterrichtseinheiten, sowie die komplette Organisation obliegt der
      ADTV Tanzschule Seidl. Die Teilnahme am Kurs findet auf eigene Gefahr
      statt. Für Verletzungen oder evtl. körperliche Schäden jeglicher Art
      übernimmt die ADTV Tanzschule Seidl keinerlei Haftung. Das Jahreshonorar
      beträgt 288€ pro Person und wird in 12 monatlichen Raten i. H. v. 24€ per
      SEPA-Lastschriftverfahren beglichen. Rückbuchungen werden mit 5€ Gebühr
      belastet. In den Schulferien findet kein Unterricht statt. Der Bankeinzug
      ist hiervon nicht beeinflusst. Film- und Fotoaufnahmen sind nur mit
      vorheriger Genehmigung erlaubt!
    </p>
    <h2>Hip Hop – 90 Minuten – 29€ mtl.</h2>
    <p>
      Der Unterricht findet 36x pro Jahr, einmal wöchentlich statt. Jede
      Unterrichtseinheit umfasst 60 Minuten incl. einer kleinen Pause. Der
      Vertrag beinhaltet eine Unterrichtseinheit pro Woche. Die Federführung für
      die Unterrichtseinheiten, sowie die komplette Organisation obliegt der
      ADTV Tanzschule Seidl. Die Teilnahme am Kurs findet auf eigene Gefahr
      statt. Für Verletzungen oder evtl. körperliche Schäden jeglicher Art
      übernimmt die ADTV Tanzschule Seidl keinerlei Haftung. Das Jahreshonorar
      beträgt 348€ pro Person und wird in 12 monatlichen Raten i. H. v. 29€ per
      SEPA-Lastschriftverfahren beglichen. Rückbuchungen werden mit 5€ Gebühr
      belastet. In den Schulferien findet kein Unterricht statt. Der Bankeinzug
      ist hiervon nicht beeinflusst. Film- und Fotoaufnahmen sind nur mit
      vorheriger Genehmigung erlaubt!
    </p>
    <h2>Zumba® Fitness – 60 Minuten – 24€ mtl.</h2>
    <p>
      Der Unterricht findet 45x pro Jahr, einmal wöchentlich statt. Jede
      Unterrichtseinheit umfasst 60 Minuten. Der Vertrag beinhaltet eine
      Unterrichtseinheit pro Woche. Die Federführung für die
      Unterrichtseinheiten, sowie die komplette Organisation obliegt der ADTV
      Tanzschule Seidl. Die Teilnahme am Kurs findet auf eigene Gefahr statt.
      Für Verletzungen oder evtl. körperliche Schäden jeglicher Art übernimmt
      die ADTV Tanzschule Seidl keinerlei Haftung. Das Jahreshonorar beträgt
      288€ pro Person und wird in 12 monatlichen Raten i. H. v. 24€ per
      SEPA-Lastschriftverfahren beglichen. Rückbuchungen werden mit 5€ Gebühr
      belastet. In den Sommerferien findet eine 3-wöchige Pause statt. Diese
      wird frühzeitig in den Kursen angekündigt. Der Bankeinzug ist hiervon
      nicht beeinflusst. Film- und Fotoaufnahmen sind nur mit vorheriger
      Genehmigung erlaubt!
    </p>
    <h2>Zumba® Fitness Flatrate – 35€ mtl.</h2>
    <p>
      Jede Unterrichtseinheit umfasst 60 Minuten. Mit der
      Zumba<strong>®&nbsp;</strong>Fitness Flatrate können Sie alle
      Zumba<strong>®&nbsp;</strong>Fitness Stunden der Tanzschule Seidl
      besuchen. Die Anzahl der von Ihnen besuchten Stunden ist unbegrenzt. Die
      Federführung für die Unterrichtseinheiten, sowie die komplette
      Organisation obliegt der ADTV Tanzschule Seidl. Die Teilnahme am Kurs
      findet auf eigene Gefahr statt. Für Verletzungen oder evtl. körperliche
      Schäden jeglicher Art übernimmt die ADTV Tanzschule Seidl keinerlei
      Haftung. Das Jahreshonorar beträgt 420€ pro Person und wird in 12
      monatlichen Raten i. H. v. 35€ per SEPA-Lastschriftverfahren beglichen.
      Rückbuchungen werden mit 5€ Gebühr belastet. In den Sommerferien findet
      eine 3-wöchige Pause statt. Diese wird frühzeitig in den Kursen
      angekündigt. Der Bankeinzug ist hiervon nicht beeinflusst. Film- und
      Fotoaufnahmen sind nur mit vorheriger Genehmigung erlaubt!
    </p>
    <h2>Ballett Erwachsene – 60 Minuten – 24€ mtl.</h2>
    <p>
      Der Unterricht findet 45x pro Jahr, einmal wöchentlich statt. Jede
      Unterrichtseinheit umfasst 60 Minuten incl. einer kleinen Pause. Der
      Vertrag beinhaltet eine Unterrichtseinheit pro Woche. Die Federführung für
      die Unterrichtseinheiten, sowie die komplette Organisation obliegt der
      ADTV Tanzschule Seidl. Die Teilnahme am Kurs findet auf eigene Gefahr
      statt. Für Verletzungen oder evtl. körperliche Schäden jeglicher Art
      übernimmt die ADTV Tanzschule Seidl keinerlei Haftung. Das Jahreshonorar
      beträgt 288€ pro Person und wird in 12 monatlichen Raten i. H. v. 24€ per
      SEPA-Lastschriftverfahren beglichen. Rückbuchungen werden mit 5€ Gebühr
      belastet. In den Schulferien findet kein Unterricht statt. Der Bankeinzug
      ist hiervon nicht beeinflusst. Film- und Fotoaufnahmen sind nur mit
      vorheriger Genehmigung erlaubt!
    </p>
  </div>
</div> -->
