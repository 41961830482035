import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, NgForm } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MailService } from '../../_services/mail.service';
import { FormData } from '../../_models/mail.model';
import { ChristmasService } from '../../_services/christmas.service';
import { Christmas, PaginationChristmas } from '../../_models/christmas.modal';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-advent',
  standalone: true,
  imports: [
    CommonModule,
    SmallHeaderComponent,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSnackBarModule,
  ],
  templateUrl: './advent.component.html',
  styleUrl: './advent.component.scss',
})
export class AdventComponent implements OnInit {
  public contact = {
    firstname: '',
    lastname: '',
    phone: '',
    mail: '',
  };

  christmas: Christmas[] = [];

  constructor(
    private mail: MailService,
    private snackbar: MatSnackBar,
    private christmasService: ChristmasService
  ) {}

  ngOnInit(): void {
    this.christmasService.getAll().subscribe({
      next: (value: PaginationChristmas) => {
        this.christmas = value.rows;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => console.log('Complete'),
    });
  }

  sendForm(contactForm: NgForm) {
    const formData: FormData = {
      mail: environment.mailTo,
      subject: 'Adventskalender Gewinnspiel',
      text: `Name: ${this.contact.firstname} ${this.contact.lastname}\nE-Mail: ${this.contact.mail}\nTelefonnummer: ${this.contact.phone}`,
      html: `<table><tr><td>Name: </td><td>${
        this.contact.firstname + ' ' + this.contact.lastname
      }</td></tr><tr><td>E-Mail: </td><td>${
        this.contact.mail
      }</td></tr><tr><td>Telefonnummer: </td><td>${
        this.contact.phone
      }</td></tr></table>`,
    };

    this.mail.sendForm(formData).subscribe({
      next: (value) => {
        this.snackbar.open('Erfolgreich versendet!', '', {
          duration: 3000,
          panelClass: 'bg-success',
        });
        contactForm.resetForm();
      },
      error: (error) => {
        console.log(error);
        this.snackbar.open('Fehler beim Senden!', '', {
          duration: 3000,
          panelClass: 'bg-danger',
        });
      },
      complete: () => console.log('Complete'),
    });
  }

  replaceImageUrl(path: string) {
    return environment.imageUrl + path;
  }
}
