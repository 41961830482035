<app-small-header
  title="Hello-Again Sonntagsbrunch Anmeldung"
></app-small-header>

<div class="content">
  <div class="container">
    <h2 class="text-primary">
      Wir laden dich kostenlos ein,<br />fülle hierzu einfach diese Anmeldung
      aus.
    </h2>
    <div class="row">
      <div class="col-lg-4">
        <img
          src="./../../../assets/helloagain.png"
          alt="Hello Again Sonntagsbrunch"
        />
      </div>
      <div class="col-lg-8">
        <form
          #contactForm="ngForm"
          class="row"
          (ngSubmit)="sendForm(contactForm)"
        >
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Dein Vorname</mat-label>
              <input
                matInput
                name="firstname"
                [(ngModel)]="contact.firstname"
                required
              />
              <mat-error>Vorname ist ein Pflichtfeld</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Dein Nachname</mat-label>
              <input
                matInput
                name="lastname"
                [(ngModel)]="contact.lastname"
                required
              />
              <mat-error>Nachname ist ein Pflichtfeld</mat-error>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Name Tanzpartner/in</mat-label>
            <input
              matInput
              name="partnerName"
              [(ngModel)]="contact.partnerName"
              required
            />
            <mat-error>Nachname ist ein Pflichtfeld</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine E-Mail Adresse</mat-label>
            <input
              matInput
              name="mail"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              type="email"
              [(ngModel)]="contact.mail"
              required
              #email="ngModel"
            />
            <mat-error *ngIf="email.touched && email.hasError('required')"
              >E-Mail Adresse ist ein Pflichtfeld
            </mat-error>
            <mat-error *ngIf="email.touched && email.hasError('pattern')"
              >Bitte gebe eine gültige E-Mail Adresse ein</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine Nachricht</mat-label>
            <textarea
              matInput
              name="message"
              [cdkTextareaAutosize]="true"
              [cdkAutosizeMinRows]="10"
              [(ngModel)]="contact.message"
            ></textarea></mat-form-field
          ><small>* Pflichtfeld</small><br /><br />
          <button
            type="submit"
            [disabled]="!contactForm.form.valid"
            mat-raised-button
            color="primary"
          >
            Absenden
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
