<app-small-header [title]="title"></app-small-header>
<div class="content">
  <div class="background">
    <div class="container">
      @if(level?.teaser && level?.teaser !== '') {
      <h2>{{ level?.teaser }}</h2>
      }
      <div class="desc" [innerHtml]="levelDescription"></div>
      @if(innerWidth > 768) {
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Nächster Einstieg</th>
            <th>Kurs</th>
            <th>Raum</th>
            <th>Termine</th>
            <th>Preis</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for(cours of courses; track cours.courseId) {
          <tr>
            <td>
              {{ cours.startEventDate }}
              {{ cours.startEventStartTime }} -
              {{ cours.startEventEndTime }}
            </td>
            <td>{{ cours.onlineName }} {{ cours.course_key }}</td>
            <td>{{ cours.room.name }}</td>
            <td
              class="events-icon"
              (click)="openEvents(getEventFuture(cours.events))"
            >
              <span class="icon-text">{{
                getEventFuture(cours.events).length
              }}</span>
              <mat-icon aria-hidden="false" aria-label="Termine öffnen"
                >help_outline</mat-icon
              >
            </td>
            @if(cours.bookingTypes && cours.bookingTypes.length > 0 &&
            cours.bookingTypes[0].itemtype === 'course'){
            <td>
              {{
                cours.bookingTypes[0].price
                  | currency : "EUR" : "symbol" : "1.2-2"
              }}
            </td>
            } @else if( !cours.bookingTypes || cours.bookingTypes.length <= 0 ||
            cours.bookingTypes[0].itemtype !== 'course') {
            <td>
              {{ cours.priceLabel }}
            </td>
            }
            <td class="center">
              @if(cours.registrationPossible){
              <button
                (click)="openBooking(cours)"
                class="small"
                mat-raised-button
                color="primary"
              >
                Anmeldung
              </button>
              } @else{
              <button class="small" mat-raised-button color="primary">
                Warteliste
              </button>
              } @if(cours.registrationHint && cours.registrationHint !== ''){
              <small
                ><br />
                @if(cours.registrationHint === 'overbooked'){
                <span> Ausgebucht </span>
                } @else if(cours.registrationHint === 'overbooked_male'){
                <span> Warteliste für Herren </span>
                } @else if(cours.registrationHint === 'overbooked_female'){
                <span> Warteliste für Damen </span>
                } @else if(cours.registrationHint === 'running'){
                <span> Kurs läuft bereits </span>
                }
              </small>
              }
            </td>
          </tr>
          } @empty {
          <tr class="nothing">
            <td colspan="6">Keine Termine</td>
          </tr>
          }
        </tbody>
      </table>
      } @else {

      <div>
        @for(cours of courses; track cours.courseId) {
        <div class="card">
          <div class="card-body">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th scope="row">Nächster Einstieg</th>
                  <td>
                    {{ cours.startEventDate }}
                    {{ cours.startEventStartTime }} -
                    {{ cours.startEventEndTime }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Kurs</th>
                  <td>{{ cours.onlineName }} {{ cours.course_key }}</td>
                </tr>
                <tr>
                  <th scope="row">Raum</th>
                  <td>{{ cours.room.name }}</td>
                </tr>
                <tr>
                  <th scope="row">Termine</th>
                  <td>
                    {{ getEventFuture(cours.events).length }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Preis</th>
                  @if(cours.bookingTypes && cours.bookingTypes.length > 0 &&
                  cours.bookingTypes[0].itemtype === 'course'){
                  <td>
                    {{
                      cours.bookingTypes[0].price
                        | currency : "EUR" : "symbol" : "1.2-2"
                    }}
                  </td>
                  } @else if( !cours.bookingTypes || cours.bookingTypes.length
                  <= 0 || cours.bookingTypes[0].itemtype !== 'course') {
                  <td>
                    {{ cours.priceLabel }}
                  </td>
                  }
                </tr>
                <tr class="center">
                  <td colspan="2">
                    @if(cours.registrationPossible){
                    <button
                      (click)="openBooking(cours)"
                      class="small"
                      mat-raised-button
                      color="primary"
                    >
                      Anmeldung
                    </button>
                    } @else{
                    <button class="small" mat-raised-button color="primary">
                      Warteliste
                    </button>
                    } @if(cours.registrationHint && cours.registrationHint !==
                    ''){
                    <small
                      ><br />
                      @if(cours.registrationHint === 'overbooked'){
                      <span> Ausgebucht </span>
                      } @else if(cours.registrationHint === 'overbooked_male'){
                      <span> Warteliste für Herren </span>
                      } @else if(cours.registrationHint ===
                      'overbooked_female'){
                      <span> Warteliste für Damen </span>
                      } @else if(cours.registrationHint === 'running'){
                      <span> Kurs läuft bereits </span>
                      }
                    </small>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        } @empty {
        <div class="card">
          <div class="card-body">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td colspan="2">Keine Termine</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        }
      </div>
      }
    </div>
  </div>
</div>
