<app-small-header
  title="Unsere Philosophie"
  [breadcrumb]="breadCrumb"
></app-small-header>

<div class="content">
  <div class="container phil">
    <h2>
      Wir sind ein junges Unternehmen, bestehend aus ausgebildeten
      Tanzlehrer(n)/innen, sowie Auszubildenden und vielen qualifizierten
      Assistenten. Mit unseren langjährigen Erfahrungen ist es unser Bestreben
      Ihnen das Tanzen auf eine korrekte, stressfreie Art beizubringen und Ihnen
      vor allem auch viel Spaß an der Sache zu vermitteln.
    </h2>
    <div class="spacer-container">
      <div class="spacer"></div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-icon>calendar_today</mat-icon>
        <h3>Wohlfühlatmosphäre</h3>
        <p>
          Eine lockere "Wohlfühlatmosphäre" während unserer Kurse ist uns
          wichtig, daher haben Sie innerhalb unseres Kurssystems mehrere
          Terminangebote in der Woche, so dass das Tanzen nicht zum
          Freizeitstress wird, sondern sich in Ihren Alltag eingliedern kann.
        </p>
      </div>
      <div class="col-md-4">
        <mat-icon>forum</mat-icon>
        <h3>Offenes Ohr</h3>
        <p>
          Der persönliche Kundenkontakt liegt uns am Herzen. Gerne stehen wir
          Ihnen immer mit einem offenen Ohr zur Verfügung, oder helfen Ihnen
          tänzerisch individuell bei einer Privatstunde weiter.
        </p>
      </div>
      <div class="col-md-4">
        <mat-icon>groups</mat-icon>
        <h3>Wöchentlichen Tanzübungsabenden</h3>
        <p>
          Auf unseren wöchentlichen Tanzübungsabenden bieten wir Ihnen
          zusätzlichen Raum, um das Gelernte während eines geselligen Abends mit
          anderen Tanzbegeisterten zu üben. Auch hier stehen wir für Sie und
          Ihre Anliegen bereit.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-icon>done_all</mat-icon>
        <h3>Vielseitiges Repertoire</h3>
        <p>
          Unser Angebot erstreckt sich über ein vielseitiges Repertoire. Jeder,
          egal ob jung oder alt, der sich gerne bewegen möchte, wird bei uns
          fündig. Paartanzkurse, Fitnesskurse, Seniorenkurse und Kinderkurse
          stehen wöchentlich auf unserem Stundenplan.
        </p>
      </div>
      <div class="col-md-6">
        <mat-icon>child_care</mat-icon>
        <h3>Junge Gäste</h3>
        <p>
          Gerade den jungen Gästen, in verschiedensten Altersgruppen, bieten wir
          ein umfangreiches Programm. Uns ist es ein großes Anliegen, den
          Kindern koordiniertes Bewegen, Ausdauer und Engagement in der Gruppe
          zu vermitteln, wovon sie im Alltag profitieren.
        </p>
      </div>
    </div>
  </div>
  <div class="background">
    <div class="container phil-cta">
      <h2>Überzeugen Sie sich selbst von unseren diversen Angeboten.</h2>
      <h3>
        Wir beraten Sie gerne und hoffen, unsere Freude am Tanzen an Sie weiter
        geben zu dürfen.
      </h3>
      <div class="row">
        <div class="col-md-6">
          <a mat-raised-button color="primary" href="/kurse">
            <mat-icon>calendar_today</mat-icon>
            Unsere Kurse
          </a>
        </div>
        <div class="col-md-6">
          <a mat-raised-button color="primary" href="/kontakt">
            <mat-icon>contact_mail</mat-icon>
            Kontaktiere uns
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
