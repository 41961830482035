import { Component } from '@angular/core';
import { BigHeaderComponent } from '../../_helpers/big-header/big-header.component';
import { Landingpages } from '../landingpages/landing.helper';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-landingpage',
  standalone: true,
  imports: [BigHeaderComponent, NgOptimizedImage],
  templateUrl: './landingpage.component.html',
  styleUrl: './landingpage.component.scss',
})
export class LandingpageComponent {
  page = Landingpages;
}
