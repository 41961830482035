<app-small-header title="Über uns"></app-small-header>

<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="img-container">
          <div class="img-div">
            <img
              [priority]="true"
              ngSrc="./../../../../assets/teambild-tanzschule-seidl.webp"
              fill
              alt="Teambild ADTV Tanzschule Seidl Amberg"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="colored">ADTV Tanzschule Seidl</h3>
        <h4>Ihre Tanzschule aus Leidenschaft in Amberg!</h4>
        <p>
          Auf über 600qm bieten wir Ihnen eine große Auswahl an verschiedenen
          Kursen für Einsteiger, Fortgeschrittene, Kinder und mehr. Unsere
          professionellen und freundlichen Tanzlehrer achten dabei auf eine
          Atmosphäre, bei der sich jeder Wohlfühlt und auch der Spaß am Tanzen
          nicht zu kurz kommt.
        </p>
      </div>
    </div>
  </div>
  <div class="background">
    <div class="container">
      <h2>Lerne uns besser kennen</h2>
      <div class="spacer-container">
        <div class="spacer"></div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <a class="card" [href]="'/ueber-uns/unsere-philosophie'">
            <div class="card-img">
              <div class="card-background">
                <img
                  ngSrc="./../../../../../assets/ueber-uns/5DSR6315_s-scaled-1000x1000.webp"
                  fill
                  alt="Über uns - Unsere Philisophie - ADTV Tanzschule Seidl Amberg"
                />
              </div>
            </div>
            <div class="card-text">Unsere Philisophie</div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <a class="card" [href]="'/ueber-uns/team'">
            <div class="card-img">
              <div class="card-background">
                <img
                  ngSrc="./../../../../../assets/ueber-uns/ts-seidl-amberg-team-1000x1000.webp"
                  fill
                  alt="Über uns - Unser Team - ADTV Tanzschule Seidl Amberg"
                />
              </div>
            </div>
            <div class="card-text">Unser Team</div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <a class="card" [href]="'/ueber-uns/ausbildung'">
            <div class="card-img">
              <div class="card-background">
                <img
                  class="fitTop"
                  ngSrc="./../../../../../assets/ueber-uns/natalie_a.webp"
                  fill
                  alt="Über uns - Ausbildung - ADTV Tanzschule Seidl Amberg"
                />
              </div>
            </div>
            <div class="card-text">Ausbildung bei uns</div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <a class="card" [href]="'/ueber-uns/unsere-raeume'">
            <div class="card-img">
              <div class="card-background">
                <img
                  ngSrc="./../../../../../assets/ueber-uns/IMG_6064-627x627.webp"
                  fill
                  alt="Über uns - Räume - ADTV Tanzschule Seidl Amberg"
                />
              </div>
            </div>
            <div class="card-text">Unsere Räume</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
