import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Christmas, PaginationChristmas } from '../_models/christmas.modal';

@Injectable({
  providedIn: 'root',
})
export class ChristmasService {
  private url = environment.url + 'christmas';
  constructor(private http: HttpClient) {}

  getAll(
    page = 0,
    size = 999999,
    title: string = ''
  ): Observable<PaginationChristmas> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('title', title);
    return this.http.get(this.url, {
      params,
    }) as Observable<PaginationChristmas>;
  }

  getById(id: number, include: string): Observable<Christmas> {
    if (include) {
      include = '?' + include;
    }
    return this.http.get(
      this.url + '/' + id + include
    ) as Observable<Christmas>;
  }
}
