<app-small-header title="Events & Tanzabende"></app-small-header>
<div class="content">
  <div class="container">
    @for(event of events; track event.id) {
    <div class="row align-middle">
      <div class="col-lg-2 align-middle my-auto eventDate">
        <div class="event-d">
          {{ event.startDate | date : "d" | number : "2.0" }}
        </div>
        <div class="event-f">{{ event.startDate | date : "MMMM" }}</div>
        <div class="event-da">{{ event.startDate | date : "EEEE" }}</div>
      </div>
      <div class="col-lg-6 col-sm-12 align-middle my-auto">
        <h4 class="mec-event-title">
          <a (click)="openEvent(event)" [innerHtml]="event.title"></a>
        </h4>
        <div class="mec-event-detail" bis_skin_checked="1">
          @if(event.venue) {
          <div class="mec-event-loc-place">
            @if(event.venue.room && event.venue.room !== ''){
            <span>{{ event.venue.room }} - </span>
            } @if(event.venue.title && event.venue.title !== '') {
            <span>{{ event.venue.title }} | </span>
            } @if(event.venue.street && event.venue.street !== ''){
            <span>{{ event.venue.street }}, </span>
            } @if(event.venue.zip && event.venue.zip !== ''){
            <span>{{ event.venue.zip }} </span>
            }@if(event.venue.city && event.venue.city !== ''){
            <span>{{ event.venue.city }}</span>
            }
          </div>
          }
          <div>
            @if(event.isExternal) { @if(event.startTime) {<span
              class="mec-start-time"
            >
              {{ event.startTime | date : "HH:mm" }}
            </span>
            -} @if(event.endTime) {
            <span class="mec-end-time">
              {{ event.endTime | date : "HH:mm" }}
            </span>
            } } @else { @if(event.startTime) {<span class="mec-start-time">
              {{ event.startTime.toString() | slice : 0 : -3 }}
            </span>
            -} @if(event.endTime) {
            <span class="mec-end-time">
              {{ event.endTime.toString() | slice : 0 : -3 }}
            </span>
            }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 align-middle my-auto mec-btn-wrapper">
        @if(event.isExternal && event.externalLink) {
        <a
          class="mec-booking-button align-middle"
          [href]="event.externalLink"
          target="_blank"
        >
          Details anzeigen
        </a>
        } @else {
        <a class="mec-booking-button align-middle" (click)="openEvent(event)">
          Details anzeigen
        </a>
        }
      </div>
    </div>
    } @empty {
    <div class="row align-middle">
      <div class="col-lg-12 align-middle my-auto text-center text-primary">
        <h2 class="mec-event-title text-primary">
          Aktuell sind keine Termine vorhanden.
        </h2>
        <h3>Schauen Sie bitte später noch einmal vorbei.</h3>
      </div>
    </div>
    }
  </div>
</div>
