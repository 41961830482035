import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Private } from '../../_models/private.model';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule, NgForm } from '@angular/forms';
import { FormData } from '../../_models/mail.model';
import { MailService } from '../../_services/mail.service';

@Component({
  selector: 'app-privatelesson-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioGroup,
    MatRadioButton,
    MatCheckbox,
    FormsModule,
  ],
  templateUrl: './privatelesson-dialog.component.html',
  styleUrl: './privatelesson-dialog.component.scss',
})
export class PrivatelessonDialogComponent {
  mail = inject(MailService);
  snackbar = inject(MatSnackBar);
  public contact = {
    name: '',
    mail: '',
    tel: '',
    callbackTime: '',
    courseType: '',
    daysAndTimes: '',
    message: '',
  };

  privacyCheck = false;

  constructor(@Inject(MAT_DIALOG_DATA) public teacher: Private) {}

  sendForm(contactForm: NgForm) {
    const formData: FormData = {
      mail: this.teacher.email ?? 'team@tanzen-amberg.de',
      subject: 'Privatstunden-Anfrage für ' + this.teacher.name,
      text: `Gewählter Lehrer: ${this.teacher.name}\nName: ${this.contact.name}\nE-Mail: ${this.contact.mail}\nTelefon: ${this.contact.tel}\nRückrufzeiten: ${this.contact.callbackTime}\nDetails: ${this.contact.courseType}\nGewünschte Zeiten: ${this.contact.daysAndTimes}\n\nNachricht:\n${this.contact.message}`,
      html: `<table><tr><td>Gewählter Lehrer: </td><td>${
        this.teacher.name
      }</td></tr><tr><td>Name: </td><td>${
        this.contact.name
      }</td></tr><tr><td>E-Mail: </td><td>${
        this.contact.mail
      }</td></tr><tr><td>Telefon: </td><td>${
        this.contact.tel
      }</td></tr><tr><td>Rückrufzeiten: </td><td>${
        this.contact.callbackTime
      }</td></tr><tr><td>Details: </td><td>${
        this.contact.courseType
      }</td></tr><tr><td>Gewünschte Zeiten: </td><td>${
        this.contact.daysAndTimes
      }</td></tr></table><br><strong>Nachricht:</strong><br>${this.contact.message.replace(
        /\n/g,
        '<br />'
      )}`,
    };

    this.mail.sendForm(formData).subscribe({
      next: (value) => {
        this.snackbar.open('Erfolgreich versendet!', '', {
          duration: 3000,
          panelClass: 'bg-success',
        });
        contactForm.resetForm();
      },
      error: (error) => {
        console.log(error);
        this.snackbar.open('Fehler beim Senden!', '', {
          duration: 3000,
          panelClass: 'bg-danger',
        });
      },
      complete: () => console.log('Complete'),
    });
  }
}
