<app-small-header
  title="Unser Team"
  [breadcrumb]="breadCrumb"
></app-small-header>
<div class="content">
  <div class="container">
    <div class="row">
      @for(member of team; track $index) {
      <div
        class="col-lg-3 col-md-4 col-sm-6 colspace d-flex align-items-stretch"
      >
        <a
          class="card"
          [href]="'/ueber-uns/team/' + member.alias + '-' + member.id"
        >
          @if(member.imagePath && member.imagePath !== '') {
          <div class="card-img">
            <img
              [priority]="
                $index < 4 || member.imagePath.includes('1685633262933')
              "
              [ngSrc]="imagePath + member.imagePath"
              fill
              sizes="100vw"
              class="card-img-top"
              [alt]="member.name + ' - ADTV Tanzschule Seidl Amberg'"
              (mouseover)="member.currentImg = member.imageHoverPath"
              (mouseout)="member.currentImg = member.imagePath"
              [hidden]="member.currentImg"
            />
            @if(member.currentImg && member.currentImg !== '') {
            <img
              [src]="imagePath + member.currentImg"
              fill
              class="card-img-top"
              [alt]="member.name + ' - ADTV Tanzschule Seidl Amberg'"
              (mouseover)="member.currentImg = member.imageHoverPath"
              (mouseout)="member.currentImg = member.imagePath"
              [hidden]="!member.currentImg"
            />
            }
          </div>
          } @else {
          <div class="card-img">
            <img
              [ngSrc]="'./../../../../assets/default_box.webp'"
              fill
              class="card-img-top"
              [alt]="member.name + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          }
          <div class="card-body">
            <h5 class="card-title">
              {{ member.name }}
              @if(member.position) {
              <br />
              <small class="card-text">
                {{ member.position }}
              </small>
              }
            </h5>
          </div>
          <div class="card-footer">Mehr erfahren</div>
        </a>
      </div>
      } @empty {
      <div class="col-12">
        <p>Es sind keine Teammitglieder vorhanden.</p>
      </div>
      }
    </div>
  </div>
</div>
