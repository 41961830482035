import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormData } from '../_models/mail.model';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private url = environment.url + 'mail';
  private mailTo = environment.mailTo;

  constructor(private http: HttpClient) {}

  sendForm(formData: FormData): Observable<any> {
    if (!formData.mail) formData.mail = this.mailTo;
    return this.http.post(this.url, formData) as Observable<any>;
  }
}
