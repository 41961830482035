import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Cours } from '../../_models/nimbuscloud.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-booking-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule],
  templateUrl: './booking-dialog.component.html',
  styleUrl: './booking-dialog.component.scss',
})
export class BookingDialogComponent implements OnInit {
  public url: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Cours,
    private sanitizer: DomSanitizer,
    private location: LocationStrategy
  ) {
    this.location.onPopState(() => {
      console.log('pressed back!');
      return false;
    });
  }

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://ts-schwandner.nimbuscloud.at/index.php?c=PublicCustomers&a=Registration&item=course&id=' +
        this.data.courseId +
        '&firstEvent=' +
        this.data.startEventId +
        '&onlineId=' +
        this.data.onlineCourseId
    );
  }
}
