<app-small-header title="Kontakt"></app-small-header>

<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <mat-icon>home</mat-icon>
        <h3>Adresse</h3>
        <p>Philipp-Melanchthon-Str. 20, 92224 Amberg</p>
      </div>

      <div class="col-md-4">
        <mat-icon>phone_iphone</mat-icon>
        <h3>Telefon</h3>
        <p>(0 96 21) 60 03 23</p>
      </div>

      <div class="col-md-4">
        <mat-icon>schedule</mat-icon>
        <h3>Öffnungszeiten</h3>
        <p>
          Mo-Do: 16:00 - 23:00 Uhr, Fr: 14:00 - 23:00 Uhr, Sa: 14:00 - 00:00
          Uhr, So: 15:00 - 23:00 Uhr
        </p>
      </div>

      <div class="col-md-4">
        <mat-icon>local_parking</mat-icon>
        <h3>Parken</h3>
        <p>Parkplätze sind vor Ort vorhanden</p>
      </div>

      <div class="col-md-4">
        <mat-icon>contact_mail</mat-icon>
        <h3>E-Mail</h3>
        <p>info&#64;tanzen-amberg.de</p>
      </div>
    </div>
  </div>

  <div class="background">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          @if(isGoogleMaps) {
          <div class="card mainCard">
            <div class="card-body">
              <h3 class="card-title">Anfahrt</h3>
              <div class="card">
                <iframe
                  width="600"
                  height="600"
                  style="
                    border: 0px;
                    pointer-events: none;
                    width: 100%;
                    min-height: 100%;
                    max-height: 100%;
                  "
                  title="Anfahrt - ADTV Tanzschule Seidl Amberg"
                  allowfullscreen=""
                  loading="lazy"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7583.961683974865!2d11.87465524402311!3d49.43743088295953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a029889f55ec23%3A0x1dbcf2d638d59dff!2sPhilipp-Melanchthon-Stra%C3%9Fe%2020%2C%2092224%20Amberg!5e0!3m2!1sde!2sde!4v1625084442793!5m2!1sde!2sde"
                  bis_size='{"x":202,"y":963,"w":495,"h":450,"abs_x":202,"abs_y":963}'
                ></iframe>
              </div>
            </div>
          </div>
          } @else {
          <div class="card mainCard">
            <div class="card-body">
              <h3 class="card-title">Anfahrt</h3>
              <div class="card">
                <div class="consent-bg"></div>
                <div class="consent-modal">
                  <div class="consent-text">
                    <h3>Karte (Google Maps) laden</h3>
                    <p>
                      Wenn Sie die Karte auf dieser Seite sehen möchten, werden
                      personenbezogene Daten an den Betreiber von Google Maps
                      gesendet und Cookies durch den Betreiber gesetzt. Daher
                      ist es möglich, dass der Anbieter Ihre Zugriffe speichert
                      und Ihr Verhalten analysieren kann. Die
                      Datenschutzerklärung von Google Maps finden Sie unter:
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        title="https://policies.google.com/privacy"
                        class="link-google"
                        >https://policies.google.com/privacy</a
                      >
                    </p>
                    <div>
                      <button
                        class="btn btn-success btn-sm"
                        id="map-privacy-check-once"
                        type="button"
                        (click)="allowGoogleMap()"
                      >
                        Karte auf dieser Seite laden
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <div class="col-lg-6">
          <div class="card mainCard">
            <div class="card-body">
              <h3 class="card-title">Kontaktiere uns!</h3>
              <form #contactForm="ngForm" (ngSubmit)="sendForm(contactForm)">
                <mat-form-field appearance="fill">
                  <mat-label>Dein Name</mat-label>
                  <input
                    matInput
                    name="name"
                    [(ngModel)]="contact.name"
                    required
                  />
                  <mat-error>Name ist ein Pflichtfeld</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Deine E-Mail Adresse</mat-label>
                  <input
                    matInput
                    name="mail"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    type="email"
                    [(ngModel)]="contact.mail"
                    required
                    #email="ngModel"
                  />
                  @if(email.touched && email.hasError('required')){
                  <mat-error> E-Mail Adresse ist ein Pflichtfeld </mat-error>
                  } @if(email.touched && email.hasError('pattern')){
                  <mat-error>
                    Bitte gebe eine gültige E-Mail Adresse ein
                  </mat-error>
                  }
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Deine Telefonnummer</mat-label>
                  <input
                    matInput
                    name="tel"
                    type="tel"
                    [(ngModel)]="contact.tel"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Firma</mat-label>
                  <input
                    matInput
                    name="company"
                    type="text"
                    [(ngModel)]="contact.company"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Deine Nachricht</mat-label>
                  <textarea
                    matInput
                    name="message"
                    [cdkTextareaAutosize]="true"
                    [cdkAutosizeMinRows]="10"
                    [(ngModel)]="contact.message"
                    required
                  ></textarea>
                  <mat-error
                    >Nachricht ist ein Pflichtfeld</mat-error
                  > </mat-form-field
                ><small>* Pflichtfeld</small><br /><br />
                <button
                  type="submit"
                  [disabled]="!contactForm.form.valid"
                  mat-raised-button
                  color="primary"
                >
                  Absenden
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container center">
    <h3 class="colored">Folge uns auf Social Media</h3>
    <div class="social">
      <div>
        <a href="https://facebook.com/tanzschule.amberg" target="_blank">
          <fa-icon [icon]="fabFacebook"></fa-icon>
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/tanzschule_seidl" target="_blank">
          <fa-icon [icon]="fabInstagram"></fa-icon>
        </a>
      </div>
      <div>
        <a href="https://www.tiktok.com/@tanzschuleseidl" target="_blank">
          <fa-icon [icon]="fabTiktok"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
