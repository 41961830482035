<ng-template
  #typesTemplate
  [ngIf]="(!typeId || typeId === '') && (!levelId || levelId === '')"
>
  <div class="row">
    @for(type of types; track type.id){
    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
      <a
        class="card"
        [href]="'/kurse/' + escapeSlash(type.externalName) + '/' + type.id"
      >
        <div class="card-img">
          @if(type.previewImage.url){
          <div class="card-background">
            <img
              [priority]="
                type.previewImage.url.includes('type-preview-14.png') ||
                type.previewImage.url.includes('type-preview-32.png') ||
                type.previewImage.url.includes('type-preview-10.png')
                  ? true
                  : false
              "
              [ngSrc]="type.newImage"
              fill
              [alt]="type.externalName + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          } @else{
          <div class="card-background">
            <img
              [priority]="true"
              [ngSrc]="'./../../../../assets/default_box.webp'"
              fill
              [alt]="type.externalName + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          }
        </div>
        <div class="card-text">{{ type.externalName }}</div>
      </a>
    </div>
    }
  </div>
</ng-template>

<ng-template
  #typesTemplate
  [ngIf]="typeId && typeId !== '' && (!levelId || levelId === '')"
>
  <div class="row">
    @for(level of levels; track level.id){
    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
      <a
        class="card"
        [href]="
          '/kurse/details/' +
          escapeSlash(level.externalName) +
          '/' +
          typeId +
          '/' +
          level.id
        "
      >
        <div class="card-img">
          @if(level.previewImage.url){
          <div class="card-background">
            <img
              [ngSrc]="level.previewImage.url"
              fill
              [alt]="level.externalName + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          } @else {
          <div class="card-background">
            <img
              [priority]="true"
              [ngSrc]="'./../../../../assets/default_box.webp'"
              fill
              [alt]="level.externalName + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          }
        </div>
        <div class="card-text">{{ level.externalName }}</div>
      </a>
    </div>
    }
  </div>
</ng-template>

<ng-template #mainTemplate [ngIf]="levels.length <= 0 && type">
  <div class="content noLevels">
    <div class="container">
      <h2>{{ type.teaser }}</h2>
      <div class="desc" [innerHtml]="type.description"></div>
    </div>
  </div>
</ng-template>
