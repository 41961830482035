import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Event } from '../../_models/nimbuscloud.model';
import { DateTime } from 'luxon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-appointment-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './appointment-dialog.component.html',
  styleUrl: './appointment-dialog.component.scss',
})
export class AppointmentDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Event[]) {}

  ngOnInit(): void {
    this.data.forEach((event) => {
      if (+event.date)
        event.date = DateTime.fromMillis(+event.date * 1000)
          .setLocale('de')
          .setZone('Europe/Berlin')
          .toFormat('EEEE, dd.MM.yyyy');
      if (+event.start_time)
        event.start_time = DateTime.fromMillis(+event.start_time * 1000)
          .setLocale('de')
          .setZone('Europe/Berlin')
          .toFormat('HH:mm');
      if (+event.end_time)
        event.end_time = DateTime.fromMillis(+event.end_time * 1000)
          .setLocale('de')
          .setZone('Europe/Berlin')
          .toFormat('HH:mm');
    });
  }
}
