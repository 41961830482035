import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import { MatButtonModule } from '@angular/material/button';
import { Private } from '../../_models/private.model';
import { environment } from '../../../environments/environment';
import { PrivateService } from '../../_services/private.service';
import { MatDialog } from '@angular/material/dialog';
import { PrivatelessonDialogComponent } from '../../_dialogs/privatelesson-dialog/privatelesson-dialog.component';

@Component({
  selector: 'app-privatelessons',
  standalone: true,
  imports: [
    CommonModule,
    SmallHeaderComponent,
    NgOptimizedImage,
    MatButtonModule,
  ],
  templateUrl: './privatelessons.component.html',
  styleUrl: './privatelessons.component.scss',
})
export class PrivatelessonsComponent implements OnInit {
  teachers: Private[] = [];
  privateService = inject(PrivateService);
  dialog = inject(MatDialog);

  imagePath = environment.imageUrl;

  breadCrumb = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Kurse',
      link: '/kurse',
    },
  ];

  ngOnInit(): void {
    this.privateService.getAll(0, 999999, '').subscribe((res) => {
      this.teachers = res.rows;
    });
  }

  splitTopics(topics: string) {
    return topics.split('\n');
  }

  openBooking(teacher: Private) {
    this.dialog.open(PrivatelessonDialogComponent, {
      data: teacher,
      width: '95vw',
      maxWidth: '800px',
      maxHeight: '95vh',
    });
  }
}
