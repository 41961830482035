<div class="main-header d-none d-lg-block">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-3" routerLink="/">
        <img
          src="./../../assets/logo-ts-seidl-amberg.webp?format=auto"
          alt="Tanzen Amberg - Tanzschule Seidl Logo"
        />
      </div>
      <div class="col-9 col-md-12 col-lg-9 icons text-end">
        <mat-icon aria-hidden="false" aria-label="Telefonnummer Icon"
          >phone_iphone</mat-icon
        >
        <span><a href="tel:09621600323">09621 / 600 323</a></span>
        <mat-icon aria-hidden="false" aria-label="Mail Adresse Icon"
          >mail_outline</mat-icon
        >
        <span
          ><a href="mailto:info@tanzen-amberg.de"
            >info&#64;tanzen-amberg.de</a
          ></span
        >
        @defer {
        <a
          href="https://facebook.com/tanzschule.amberg"
          target="_blank"
          class="fab"
        >
          <fa-icon [icon]="fabFacebook"></fa-icon>
        </a>
        <a
          href="https://www.instagram.com/tanzschule_seidl"
          target="_blank"
          class="fab"
        >
          <fa-icon [icon]="fabInstagram"></fa-icon>
        </a>
        <a
          href="https://www.tiktok.com/@tanzschuleseidl"
          target="_blank"
          class="fab"
        >
          <fa-icon [icon]="fabTiktok"></fa-icon>
        </a>
        }
      </div>
    </div>
  </div>
</div>
<nav
  class="navbar navbar-expand-lg navbar-light bg-light"
  [ngClass]="isScrolled ? 'fixed-top' : ''"
>
  <div class="container">
    <div class="break d-none d-lg-block"></div>
    <a
      class="navbar-brand d-lg-none"
      routerLink="/"
      data-bs-toggle="collapse"
      data-bs-target=".navbar-collapse.show"
    >
      <img
        src="./../../assets/logo-ts-seidl-amberg.webp?format=auto"
        alt="Tanzen Amberg - Tanzschule Seidl Logo"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            routerLinkActive=""
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            [ngClass]="currentUrl.startsWith('/ueber-uns') ? 'active' : ''"
          >
            Über uns
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a
                class="dropdown-item"
                [ngClass]="currentUrl === '/ueber-uns' ? 'active' : ''"
                href="/ueber-uns"
                >Über uns</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                [ngClass]="
                  currentUrl.startsWith('/ueber-uns/team') ? 'active' : ''
                "
                href="/ueber-uns/team"
                >Unser Team</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                [ngClass]="
                  currentUrl.startsWith('/ueber-uns/unsere-philosophie')
                    ? 'active'
                    : ''
                "
                href="/ueber-uns/unsere-philosophie"
                >Unsere Philosophie</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                [ngClass]="
                  currentUrl.startsWith('/ueber-uns/ausbildung') ? 'active' : ''
                "
                href="/ueber-uns/ausbildung"
                >Ausbildung</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                [ngClass]="
                  currentUrl.startsWith('/ueber-uns/unsere-raeume')
                    ? 'active'
                    : ''
                "
                href="/ueber-uns/unsere-raeume"
                >Unsere Räume</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            [ngClass]="currentUrl.startsWith('/kurse') ? 'active' : ''"
            href="/kurse"
            >Kurse</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            [ngClass]="
              currentUrl.startsWith('/events-tanzabende') ? 'active' : ''
            "
            href="/events-tanzabende"
            >Events & Tanzabende</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            [ngClass]="currentUrl.startsWith('/gutscheine') ? 'active' : ''"
            href="/gutscheine"
            >Gutscheine</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            [ngClass]="currentUrl.startsWith('/kontakt') ? 'active' : ''"
            href="/kontakt"
            >Kontakt</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link small"
            aria-current="page"
            href="https://community.tanzen-amberg.de/"
            mat-raised-button
            color="primary"
            target="_blank"
          >
            Kundencenter
          </a>
        </li>
        <li class="nav-item d-lg-none social">
          <a
            href="https://facebook.com/tanzschule.amberg"
            target="_blank"
            class="fab fa-facebook"
          ></a>
          <a
            href="https://www.instagram.com/tanzschule_seidl"
            target="_blank"
            class="fab fa-instagram"
          ></a>
          <a
            href="https://www.tiktok.com/@tanzschuleseidl"
            target="_blank"
            class="fab fa-tiktok"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>

<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h5>ADTV Tanzschule Seidl GmbH</h5>
        <p>
          <strong>Anschrift:</strong><br />
          Philipp-Melanchthon-Str. 20 92224 Amberg
        </p>
        <p>
          <strong>Telefon:</strong><br />
          (0 96 21) 60 03 23
        </p>
        <p>
          <strong>E-Mail</strong><br />
          info&#64;tanzen-amberg.de
        </p>
      </div>
      <div class="col-md-4">
        <h5>Öffnungszeiten</h5>
        <p>
          <strong>Montag-Donnerstag</strong><br />
          16:00 - 23:00 Uhr
        </p>
        <p>
          <strong>Freitag</strong><br />
          14:00 - 23:00 Uhr
        </p>
        <p>
          <strong>Samstag</strong><br />
          14:00 - 00:00 Uhr
        </p>
        <p>
          <strong>Sonntag</strong><br />
          15:00 - 23:00 Uhr
        </p>
      </div>
      <div class="col-md-4">
        <h5>Newsletter</h5>
        <p>
          Melde dich zu unserem Newsletter an.<br />Hier bekommst du alle Infos
          rund um deine Lieblings-Tanzschule
        </p>
        <br />
        <a
          href="http://eepurl.com/gZQb8r"
          target="_blank"
          mat-raised-button
          color="primary"
          >Zur Newsletter-Anmeldung</a
        >
      </div>
    </div>
    <div class="center">
      <img
        src="./../../assets/footer-logo.webp?format=auto"
        alt="ADTV Tanzschule Amberg Seidl - Logo klein"
      />
      <p>© {{ anio }} Tanzschule Seidl Amberg</p>
      <p>
        <a href="/impressum">Impressum</a> |
        <a href="/datenschutz">Datenschutzerklärung</a> |
        <a href="/agb">AGB</a>
      </p>
    </div>
  </div>
</footer>
