<app-small-header
  title="Kurshonorar"
  [breadcrumb]="breadCrumb"
></app-small-header>
<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" [innerHTML]="courseFee.text"></div>
        </div>
      </div>
    </div>
  </div>
</div>
