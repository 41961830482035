<h2 mat-dialog-title>Termine</h2>
<mat-dialog-content class="mat-typography">
  <table class="table table-striped table-hover">
    <thead>
      <tr class="accordion">
        <th>Datum</th>
        <th>Block</th>
        <th>Einstieg</th>
        <th>Voll</th>
      </tr>
    </thead>
    <tbody>
      @for(event of data; track event.id){
      <tr>
        <td class="nounderline">
          {{ event.date }}, {{ event.start_time }} - {{ event.end_time }}
        </td>
        <td>{{ event.blockName }}</td>
        <td>{{ event.isStart ? "Ja" : "Nein" }}</td>
        <td>{{ event.isFull ? "Ja" : "Nein" }}</td>
      </tr>
      } @empty {
      <tr>
        <td colspan="4" class="text-center">
          <h3>Keine Termine vorhanden</h3>
        </td>
      </tr>
      }
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>
