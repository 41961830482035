<app-big-header
  [title]="
    'Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunden, Boogie Woogie, Discofox, Salsa, Hip Hop, Zumba, Kinder-Teenie-Tanzen.'
  "
></app-big-header>
<div class="container">
  <div class="row">
    @for(item of page; track item.alias){
    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
      <a class="card" [href]="item.alias">
        <div class="card-img">
          @if(item.image){
          <div class="card-background">
            <img
              [ngSrc]="item.image"
              fill
              [alt]="item.title + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          } @else{
          <div class="card-background">
            <img
              [priority]="true"
              [ngSrc]="'./../../../../assets/default_box.webp'"
              fill
              [alt]="item.title + ' - ADTV Tanzschule Seidl Amberg'"
            />
          </div>
          }
        </div>
        <div class="card-text">{{ item.title }}</div>
      </a>
    </div>
    }
  </div>
</div>
