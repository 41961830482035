<app-small-header title="Datenschutzerklärung"></app-small-header>
<div id="content" class="content" role="main" bis_skin_checked="1">
  <div class="container">
    <p>
      Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
      der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) im
      Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres
      Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und
      Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media
      Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
      Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“
      oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
      Datenschutzgrundverordnung (DSGVO).
    </p>
    <h3>Verantwortlicher</h3>
    <p>
      ADTV Tanzschule Seidl GmbH<br />Geschäftsführerin:&nbsp;Elisabeth&nbsp;Seidl<br />Steuer
      Nummer: 201/272/40109
    </p>
    <p>
      Philipp-Melanchthon-Str. 20<br />92224 Amberg<br />Telefon: (0 96 21) 60
      03 23<br />Mail: info&#64;tanzen-amberg.de
    </p>
    <h3>Arten der verarbeiteten Daten</h3>
    <p>
      – Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).<br />–
      Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />– Inhaltsdaten (z.B.,
      Texteingaben, Fotografien, Videos).<br />– Nutzungsdaten (z.B., besuchte
      Webseiten, Interesse an Inhalten, Zugriffszeiten).<br />–
      Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
    </p>
    <h3>Kategorien betroffener Personen</h3>
    <p>
      Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
      betroffenen Personen zusammenfassend auch als „Nutzer“).
    </p>
    <h3>Zweck der Verarbeitung</h3>
    <p>
      – Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
      Inhalte.<br />– Beantwortung von Kontaktanfragen und Kommunikation mit
      Nutzern.<br />– Sicherheitsmaßnahmen.<br />– Reichweitenmessung/Marketing
    </p>
    <h3>Verwendete Begrifflichkeiten</h3>
    <p>
      „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
      identifizierte oder identifizierbare natürliche Person (im Folgenden
      „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
      Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
      zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten,
      zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
      besonderen Merkmalen identifiziert werden kann, die Ausdruck der
      physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
      kulturellen oder sozialen Identität dieser natürlichen Person sind.
    </p>
    <p>
      „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
      ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
      personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
      jeden Umgang mit Daten.
    </p>
    <p>
      „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer
      Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
      Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet
      werden können, sofern diese zusätzlichen Informationen gesondert
      aufbewahrt werden und technischen und organisatorischen Maßnahmen
      unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht
      einer identifizierten oder identifizierbaren natürlichen Person zugewiesen
      werden.
    </p>
    <p>
      „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener
      Daten, die darin besteht, dass diese personenbezogenen Daten verwendet
      werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
      Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich
      Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben,
      Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
      dieser natürlichen Person zu analysieren oder vorherzusagen.
    </p>
    <p>
      Als „Verantwortlicher“ wird die natürliche oder juristische Person,
      Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
      anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
      Daten entscheidet, bezeichnet.
    </p>
    <p>
      „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde,
      Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
      Verantwortlichen verarbeitet.
    </p>
    <h3>Maßgebliche Rechtsgrundlagen</h3>
    <p>
      Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
      unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
      Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern
      die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
      Folgendes:<br />Die Rechtsgrundlage für die Einholung von Einwilligungen
      ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;<br />Die Rechtsgrundlage für
      die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung
      vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1
      lit. b DSGVO;<br />Die Rechtsgrundlage für die Verarbeitung zur Erfüllung
      unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;<br />Für
      den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer
      anderen natürlichen Person eine Verarbeitung personenbezogener Daten
      erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
      Rechtsgrundlage.<br />Die Rechtsgrundlage für die erforderliche
      Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse
      liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
      Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO.<br />Die
      Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten
      Interessen ist Art. 6 Abs. 1 lit. f DSGVO.<br />Die Verarbeitung von Daten
      zu anderen Zwecken als denen, zu denen sie ehoben wurden, bestimmt sich
      nach den Vorgaben des Art 6 Abs. 4 DSGVO.<br />Die Verarbeitung von
      besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO)
      bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.
    </p>
    <h3>Sicherheitsmaßnahmen</h3>
    <p>
      Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung
      des Stands der Technik, der Implementierungskosten und der Art, des
      Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
      unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für
      die Rechte und Freiheiten natürlicher Personen, geeignete technische und
      organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
      gewährleisten.
    </p>
    <p>
      Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
      Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
      Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe,
      Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des
      Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
      Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
      Daten gewährleisten. Ferner berücksichtigen wir den Schutz
      personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
      Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
      Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen.
    </p>
    <h3>
      Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und
      Dritten
    </h3>
    <p>
      Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen
      und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder
      Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
      die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
      Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an
      Zahlungsdienstleister, zur Vertragserfüllung erforderlich ist), Nutzer
      eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf
      Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
      Beauftragten, Webhostern, etc.).
    </p>
    <p>
      Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe
      offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt
      dies insbesondere zu administrativen Zwecken als berechtigtes Interesse
      und darüberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden
      Grundlage.
    </p>
    <h3>Übermittlungen in Drittländer</h3>
    <p>
      Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union
      (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
      Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von
      Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an andere
      Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es zur
      Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer
      Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage
      unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder
      vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem
      Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die
      Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der
      offiziell anerkannten Feststellung eines der EU entsprechenden
      Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder
      Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen.
    </p>
    <h3>Rechte der betroffenen Personen</h3>
    <p>
      Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende
      Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf
      weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
      Vorgaben.
    </p>
    <p>
      Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die
      Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
      betreffenden unrichtigen Daten zu verlangen.
    </p>
    <p>
      Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen,
      dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
      Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der
      Daten zu verlangen.
    </p>
    <p>
      Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie
      uns bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu
      erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
    </p>
    <p>
      Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine
      Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
    </p>
    <h3>Widerrufsrecht</h3>
    <p>
      Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft
      zu widerrufen.
    </p>
    <h3>Widerspruchsrecht</h3>
    <p>
      <strong
        >Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
        Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der
        Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
        Direktwerbung erfolgen.</strong
      >
    </p>
    <h3>Cookies und Widerspruchsrecht bei Direktwerbung</h3>
    <p>
      Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
      Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche
      Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
      einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während
      oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern.
      Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“,
      werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
      Onlineangebot verlässt und seinen Browser schließt. In einem solchen
      Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein
      Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden
      Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert
      bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer
      diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie
      die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung
      oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
      Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
      der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur
      dessen Cookies sind spricht man von „First-Party Cookies“).
    </p>
    <p>
      Wir können temporäre und permanente Cookies einsetzen und klären hierüber
      im Rahmen unserer Datenschutzerklärung auf.
    </p>
    <p>
      Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert
      werden, werden sie gebeten die entsprechende Option in den
      Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies
      können in den Systemeinstellungen des Browsers gelöscht werden. Der
      Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
      Onlineangebotes führen.
    </p>
    <p>
      Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
      Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste,
      vor allem im Fall des Trackings, über die US-amerikanische Seite&nbsp;<a
        href="http://www.aboutads.info/choices/"
        >http://www.aboutads.info/choices/</a
      >&nbsp;oder die EU-Seite&nbsp;<a href="http://www.youronlinechoices.com/"
        >http://www.youronlinechoices.com/</a
      >&nbsp;erklärt werden. Des Weiteren kann die Speicherung von Cookies
      mittels deren Abschaltung in den Einstellungen des Browsers erreicht
      werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
      dieses Onlineangebotes genutzt werden können.
    </p>
    <h3>Löschung von Daten</h3>
    <p>
      Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
      Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht
      im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
      bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung
      nicht mehr erforderlich sind und der Löschung keine gesetzlichen
      Aufbewahrungspflichten entgegenstehen.
    </p>
    <p>
      Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich
      zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
      D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet.
      Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
      aufbewahrt werden müssen.
    </p>
    <h3>Änderungen und Aktualisierungen der Datenschutzerklärung</h3>
    <p>
      Wir bitten Sie sich regelmäßig über den Inhalt unserer
      Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
      an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
      dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen
      eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
      individuelle Benachrichtigung erforderlich wird.
    </p>
    <h3>Bestellabwicklung im Onlineshop und Kundenkonto</h3>
    <p>
      Wir verarbeiten die Daten unserer Kunden im Rahmen der Bestellvorgänge in
      unserem Onlineshop, um ihnen die Auswahl und die Bestellung der gewählten
      Produkte und Leistungen, sowie deren Bezahlung und Zustellung, bzw.
      Ausführung zu ermöglichen.
    </p>
    <p>
      Zu den verarbeiteten Daten gehören Bestandsdaten, Kommunikationsdaten,
      Vertragsdaten, Zahlungsdaten und zu den von der Verarbeitung betroffenen
      Personen gehören unsere Kunden, Interessenten und sonstige
      Geschäftspartner. Die Verarbeitung erfolgt zum Zweck der Erbringung von
      Vertragsleistungen im Rahmen des Betriebs eines Onlineshops, Abrechnung,
      Auslieferung und der Kundenservices. Hierbei setzen wir Session Cookies
      für die Speicherung des Warenkorb-Inhalts und permanente Cookies für die
      Speicherung des Login-Status ein.
    </p>
    <p>
      Die Verarbeitung erfolgt zur Erfüllung unserer Leistungen und Durchführung
      vertraglicher Maßnahmen (z.B. Durchführung von Bestellvorgängen) und
      soweit sie gesetzlich vorgeschrieben ist (z.B., gesetzlich erforderliche
      Archivierung von Geschäftsvorgängen zu Handels und Steuerzwecken). Dabei
      sind die als erforderlich gekennzeichneten Angaben zur Begründung und
      Erfüllung des Vertrages erforderlich. Die Daten offenbaren wir gegenüber
      Dritten nur im Rahmen der Auslieferung, Zahlung oder im Rahmen der
      gesetzlichen Erlaubnisse und Pflichten, als auch wenn dies auf Grundlage
      unserer berechtigten Interessen erfolgt, worüber wir Sie im Rahmen dieser
      Datenschutzerklärung informieren (z.B., gegenüber Rechts- und
      Steuerberatern, Finanzinstituten, Frachtunternehmen sowie Behörden).
    </p>
    <p>
      Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere
      ihre Bestellungen einsehen können. Im Rahmen der Registrierung, werden die
      erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten
      sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden.
      Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im
      Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung
      ist aus handels- oder steuerrechtlichen Gründen notwendig. Angaben im
      Kundenkonto verbleiben bis zu dessen Löschung mit anschließender
      Archivierung im Fall einer rechtlichen Verpflichtung oder unser
      berechtigten Interessen (z.B., im Fall von Rechtsstreitigkeiten). Es
      obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem
      Vertragsende zu sichern.
    </p>
    <p>
      Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme
      unserer Onlinedienste, speichern wir die IP-Adresse und den Zeitpunkt der
      jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer
      berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und
      sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte
      erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer
      gesetzlichen Ansprüche als berechtigtes Interesse erforderlich oder es
      besteht hierzu eine gesetzliche Verpflichtung.
    </p>
    <p>
      Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs- und
      sonstiger vertraglicher Rechte oder Pflichten (z.B., Zahlungsansprüche
      oder Leistungspflichten aus Verträgen mir Kunden), wobei die
      Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft
      wird; im Fall der Aufbewahrung aufgrund gesetzlicher
      Archivierungspflichten, erfolgt die Löschung insoweit nach deren Ablauf.
    </p>
    <h3>Agenturdienstleistungen</h3>
    <p>
      Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen
      Leistungen zu denen konzeptionelle und strategische Beratung,
      Kampagnenplanung, Software- und Designentwicklung/-beratung oder Pflege,
      Umsetzung von Kampagnen und Prozessen/ Handling, Serveradministration,
      Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören.
    </p>
    <p>
      Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen
      oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern), Inhaltsdaten
      (z.B., Texteingaben, Fotografien, Videos), Vertragsdaten (z.B.,
      Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B., Bankverbindung,
      Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung
      und Erfolgsmessung von Marketingmaßnahmen). Besondere Kategorien
      personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn
      diese Bestandteile einer beauftragten Verarbeitung sind. Zu den
      Betroffenen gehören unsere Kunden, Interessenten sowie deren Kunden,
      Nutzer, Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der
      Verarbeitung besteht in der Erbringung von Vertragsleistungen, Abrechnung
      und unserem Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben
      sich aus Art. 6 Abs. 1 lit. b DSGVO (vertragliche Leistungen), Art. 6 Abs.
      1 lit. f DSGVO (Analyse, Statistik, Optimierung, Sicherheitsmaßnahmen).
      Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen
      Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer
      Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen
      eines Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen
      eines Auftrags überlassenen Daten handeln wir entsprechend den Weisungen
      der Auftraggeber sowie der gesetzlichen Vorgaben einer
      Auftragsverarbeitung gem. Art. 28 DSGVO und verarbeiten die Daten zu
      keinen anderen, als den auftragsgemäßen Zwecken.
    </p>
    <p>
      Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
      vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der Daten
      wird alle drei Jahre überprüft; im Fall der gesetzlichen
      Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6 J, gem. §
      257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1 AO). Im Fall von Daten, die uns
      gegenüber im Rahmen eines Auftrags durch den Auftraggeber offengelegt
      wurden, löschen wir die Daten entsprechend den Vorgaben des Auftrags,
      grundsätzlich nach Ende des Auftrags.
    </p>
    <h3>
      Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
    </h3>
    <p>
      Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation
      unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen
      Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben
      Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen
      verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO,
      Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
      Interessenten, Geschäftspartner und Websitebesucher betroffen. Der Zweck
      und unser Interesse an der Verarbeitung liegt in der Administration,
      Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben
      die der Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung
      unserer Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung
      der Daten im Hinblick auf vertragliche Leistungen und die vertragliche
      Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten
      genannten Angaben.
    </p>
    <p>
      Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung,
      Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere
      Gebührenstellen und Zahlungsdienstleister.
    </p>
    <p>
      Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen
      Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen
      Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese
      mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich
      dauerhaft.
    </p>
    <h3>Kontaktaufnahme</h3>
    <p>
      Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon
      oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung
      der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im
      Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f.
      (andere Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer können in
      einem Customer-Relationship-Management System („CRM System“) oder
      vergleichbarer Anfragenorganisation gespeichert werden.
    </p>
    <p>
      Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
      überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
      gesetzlichen Archivierungspflichten.
    </p>
    <h3>Hosting und E-Mail-Versand</h3>
    <p>
      Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
      Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
      Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
      Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
      Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes
      einsetzen.
    </p>
    <p>
      Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
      Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
      Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
      Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
      effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem.
      Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
      Auftragsverarbeitungsvertrag).
    </p>
    <h3>Erhebung von Zugriffsdaten und Logfiles</h3>
    <p>
      Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten
      Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden
      Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte
      Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
      Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge,
      Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das
      Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
      IP-Adresse und der anfragende Provider.
    </p>
    <p>
      Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
      von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen
      gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu
      Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
      jeweiligen Vorfalls von der Löschung ausgenommen.
    </p>
    <h3>Onlinepräsenzen in sozialen Medien</h3>
    <p>
      Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
      Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
      kommunizieren und sie dort über unsere Leistungen informieren zu können.
    </p>
    <p>
      Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes
      der Europäischen Union verarbeitet werden können. Hierdurch können sich
      für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
      der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die unter
      dem Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass sie sich
      damit verpflichten, die Datenschutzstandards der EU einzuhalten.
    </p>
    <p>
      Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und
      Werbezwecke verarbeitet. So können z.B. aus dem Nutzungsverhalten und sich
      daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden.
      Die Nutzungsprofile können wiederum verwendet werden, um z.B.
      Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten, die
      mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden
      im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das
      Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner
      können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern
      verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer
      Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt
      sind).
    </p>
    <p>
      Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf
      Grundlage unserer berechtigten Interessen an einer effektiven Information
      der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f.
      DSGVO. Falls die Nutzer von den jeweiligen Anbietern um eine Einwilligung
      in die Datenverarbeitung gebeten werden (d.h. ihr Einverständnis z.B. über
      das Anhaken eines Kontrollkästchens oder Bestätigung einer Schaltfläche
      erklären) ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a.,
      Art. 7 DSGVO.
    </p>
    <p>
      Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der
      Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die nachfolgend
      verlinkten Angaben der Anbieter.
    </p>
    <p>
      Auch im Fall von Auskunftsanfragen und der Geltendmachung von
      Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei den
      Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
      Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
      ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
      können Sie sich an uns wenden.
    </p>
    <p>
      – Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
      Harbour, Dublin 2, Irland) – Datenschutzerklärung:&nbsp;<a
        href="https://www.facebook.com/about/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.facebook.com/about/privacy/</a
      >, Opt-Out:&nbsp;<a
        href="https://www.facebook.com/settings?tab=ads"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.facebook.com/settings?tab=ads</a
      >&nbsp;und&nbsp;<a
        href="http://www.youronlinechoices.com/"
        target="_blank"
        rel="noreferrer noopener"
        >http://www.youronlinechoices.com</a
      >, Privacy Shield:&nbsp;<a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a
      >.
    </p>
    <p>
      – Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View,
      CA 94043, USA) – Datenschutzerklärung: &nbsp;<a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer noopener"
        >https://policies.google.com/privacy</a
      >, Opt-Out:&nbsp;<a
        href="https://adssettings.google.com/authenticated"
        target="_blank"
        rel="noreferrer noopener"
        >https://adssettings.google.com/authenticated</a
      >, Privacy Shield:&nbsp;<a
        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a
      >.
    </p>
    <p>
      – Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA)
      – Datenschutzerklärung/ Opt-Out:&nbsp;<a
        href="http://instagram.com/about/legal/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >http://instagram.com/about/legal/privacy/</a
      >.
    </p>
    <p>
      – Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA
      94103, USA) – Datenschutzerklärung:&nbsp;<a
        href="https://twitter.com/de/privacy"
        target="_blank"
        rel="noreferrer noopener"
        >https://twitter.com/de/privacy</a
      >, Opt-Out:&nbsp;<a
        href="https://twitter.com/personalization"
        target="_blank"
        rel="noreferrer noopener"
        >https://twitter.com/personalization</a
      >, Privacy Shield:&nbsp;<a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a
      >.
    </p>
    <p>
      – Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) –
      Datenschutzerklärung/ Opt-Out:&nbsp;<a
        href="https://about.pinterest.com/de/privacy-policy"
        target="_blank"
        rel="noreferrer noopener"
        >https://about.pinterest.com/de/privacy-policy</a
      >.
    </p>
    <p>
      – LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2,
      Irland) – Datenschutzerklärung&nbsp;<a
        href="https://www.linkedin.com/legal/privacy-policy"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.linkedin.com/legal/privacy-policy</a
      >&nbsp;, Opt-Out:&nbsp;<a
        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
      >, Privacy Shield:&nbsp;<a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a
      >.
    </p>
    <p>
      – Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) –
      Datenschutzerklärung/ Opt-Out:&nbsp;<a
        href="https://privacy.xing.com/de/datenschutzerklaerung"
        target="_blank"
        rel="noreferrer noopener"
        >https://privacy.xing.com/de/datenschutzerklaerung</a
      >.
    </p>
    <p>
      – Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United
      Kingdom) – Datenschutzerklärung/ Opt-Out:&nbsp;<a
        href="https://wakelet.com/privacy.html"
        target="_blank"
        rel="noreferrer noopener"
        >https://wakelet.com/privacy.html</a
      >.
    </p>
    <p>
      – Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin,
      Deutschland) – Datenschutzerklärung/ Opt-Out:&nbsp;<a
        href="https://soundcloud.com/pages/privacy"
        target="_blank"
        rel="noreferrer noopener"
        >https://soundcloud.com/pages/privacy</a
      >.
    </p>
    <h3>Einbindung von Diensten und Inhalten Dritter</h3>
    <p>
      Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
      berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
      wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs.
      1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um
      deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden
      (nachfolgend einheitlich bezeichnet als “Inhalte”).
    </p>
    <p>
      Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
      IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
      nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
      Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
      Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
      zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
      genannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“
      bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
      „Pixel-Tags“ können Informationen, wie der Besucherverkehr auf den Seiten
      dieser Website ausgewertet werden. Die pseudonymen Informationen können
      ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter
      anderem technische Informationen zum Browser und Betriebssystem,
      verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
      unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus
      anderen Quellen verbunden werden.
    </p>
    <h3>Youtube</h3>
    <p>
      Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC,
      1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
      Datenschutzerklärung:&nbsp;<a
        href="https://www.google.com/policies/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.google.com/policies/privacy/</a
      >, Opt-Out:&nbsp;<a
        href="https://adssettings.google.com/authenticated"
        target="_blank"
        rel="noreferrer noopener"
        >https://adssettings.google.com/authenticated</a
      >.
    </p>
    <h3>Google Fonts</h3>
    <p>
      Wir binden die Schriftarten („Google Fonts“) des Anbieters Google LLC,
      1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
      Datenschutzerklärung:&nbsp;<a
        href="https://www.google.com/policies/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.google.com/policies/privacy/</a
      >, Opt-Out:&nbsp;<a
        href="https://adssettings.google.com/authenticated"
        target="_blank"
        rel="noreferrer noopener"
        >https://adssettings.google.com/authenticated</a
      >.
    </p>
    <h3>Google Maps</h3>
    <p>
      Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google
      LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den
      verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der
      Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im
      Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden. Die
      Daten können in den USA verarbeitet werden. Datenschutzerklärung:&nbsp;<a
        href="https://www.google.com/policies/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.google.com/policies/privacy/</a
      >, Opt-Out:&nbsp;<a
        href="https://adssettings.google.com/authenticated"
        target="_blank"
        rel="noreferrer noopener"
        >https://adssettings.google.com/authenticated</a
      >.
    </p>
    <h3>Verwendung von Facebook Social Plugins</h3>
    <p>
      Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse
      an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres
      Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins
      („Plugins“) des sozialen Netzwerkes facebook.com, welches von der Facebook
      Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
      betrieben wird („Facebook“).<br />Hierzu können z.B. Inhalte wie Bilder,
      Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte
      dieses Onlineangebotes innerhalb von Facebook teilen können. Die Liste und
      das Aussehen der Facebook Social Plugins kann hier eingesehen
      werden:&nbsp;<a
        href="https://developers.facebook.com/docs/plugins/"
        target="_blank"
        rel="noreferrer noopener"
        >https://developers.facebook.com/docs/plugins/</a
      >.
    </p>
    <p>
      Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
      hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a
      >).
    </p>
    <p>
      Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein
      solches Plugin enthält, baut sein Gerät eine direkte Verbindung mit den
      Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt
      an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot
      eingebunden. Dabei können aus den verarbeiteten Daten Nutzungsprofile der
      Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang der
      Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die
      Nutzer daher entsprechend unserem Kenntnisstand.
    </p>
    <p>
      Durch die Einbindung der Plugins erhält Facebook die Information, dass ein
      Nutzer die entsprechende Seite des Onlineangebotes aufgerufen hat. Ist der
      Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
      Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum
      Beispiel den Like Button betätigen oder einen Kommentar abgeben, wird die
      entsprechende Information von Ihrem Gerät direkt an Facebook übermittelt
      und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist,
      besteht trotzdem die Möglichkeit, dass Facebook seine IP-Adresse in
      Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine
      anonymisierte IP-Adresse gespeichert.
    </p>
    <p>
      Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
      Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und
      Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können
      diese den Datenschutzhinweisen von Facebook entnehmen:&nbsp;<a
        href="https://www.facebook.com/about/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.facebook.com/about/privacy/</a
      >.
    </p>
    <p>
      Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über
      dieses Onlineangebot Daten über ihn sammelt und mit seinen bei Facebook
      gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der Nutzung
      unseres Onlineangebotes bei Facebook ausloggen und seine Cookies löschen.
      Weitere Einstellungen und Widersprüche zur Nutzung von Daten für
      Werbezwecke, sind innerhalb der Facebook-Profileinstellungen
      möglich:&nbsp;<a
        href="https://www.facebook.com/settings?tab=ads"
        target="_blank"
        rel="noreferrer noopener"
        >https://www.facebook.com/settings?tab=ads</a
      >&nbsp;&nbsp;oder über die US-amerikanische Seite&nbsp;<a
        href="http://www.aboutads.info/choices/"
        target="_blank"
        rel="noreferrer noopener"
        >http://www.aboutads.info/choices/</a
      >&nbsp;&nbsp;oder die EU-Seite&nbsp;<a
        href="http://www.youronlinechoices.com/"
        target="_blank"
        rel="noreferrer noopener"
        >http://www.youronlinechoices.com/</a
      >. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für
      alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
    </p>
    <h3>Instagram</h3>
    <p>
      Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
      Dienstes Instagram, angeboten durch die Instagram Inc., 1601 Willow Road,
      Menlo Park, CA, 94025, USA, eingebunden werden. Hierzu können z.B. Inhalte
      wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer
      Inhalte dieses Onlineangebotes innerhalb von Instagram teilen können.
      Sofern die Nutzer Mitglieder der Plattform Instagram sind, kann Instagram
      den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
      Nutzer zuordnen. Datenschutzerklärung von Instagram:&nbsp;<a
        href="http://instagram.com/about/legal/privacy/"
        target="_blank"
        rel="noreferrer noopener"
        >http://instagram.com/about/legal/privacy/</a
      >.
    </p>
    <p>
      <a
        href="https://datenschutz-generator.de/"
        target="_blank"
        rel="noreferrer noopener"
        >Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a
      >
    </p>
  </div>
</div>
