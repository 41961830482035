<h2 mat-dialog-title class="colored" [innerHtml]="event.title"></h2>
<mat-dialog-content class="mat-typography">
  <div class="mec-single-event-bar" bis_skin_checked="1">
    <div class="mec-single-event-date" bis_skin_checked="1">
      <h3 class="mec-date">Datum</h3>
      <dl>
        <dd>
          <abbr class="mec-events-abbr"
            ><span class="mec-start-date-label" itemprop="startDate">{{
              event.startDate | date : "dd.MM.yyyy"
            }}</span></abbr
          >
        </dd>
      </dl>
    </div>

    <div class="mec-single-event-time" bis_skin_checked="1">
      <h3 class="mec-time">Uhrzeit</h3>
      <dl>
        <dd>
          <abbr class="mec-events-abbr">
            @if(event.startTime) {
            <span> {{ event.startTime.toString() | slice : 0 : -3 }} - </span>
            } @if(event.endTime) {
            <span>
              {{ event.endTime.toString() | slice : 0 : -3 }}
            </span>
            }
          </abbr>
        </dd>
      </dl>
    </div>
    @if(event.price) {
    <div class="mec-event-cost" bis_skin_checked="1">
      <h3 class="mec-cost">Kosten</h3>
      <dl>
        <dd class="mec-events-event-cost">{{ event.price }}</dd>
      </dl>
    </div>
    }
  </div>
  <p><br /></p>
  @if(event.description && event.description !== '') {
  <h3>Beschreibung:</h3>
  <h4 [innerHtml]="event.description"></h4>
  } @if(event.venue) {
  <h3>Veranstaltungsort:</h3>
  <h4>
    @if(event.venue.room && event.venue.room !== '') {
    <span> {{ event.venue.room }} - </span>
    } @if(event.venue.title && event.venue.title !== '') {
    <span> {{ event.venue.title }} | </span>
    } @if(event.venue.street && event.venue.street !== '') {
    <span>{{ event.venue.street }}, </span>
    } @if(event.venue.zip && event.venue.zip !== '') {
    <span>{{ event.venue.zip }} </span>
    } @if(event.venue.city && event.venue.city !== '') {
    <span>{{ event.venue.city }}</span>
    }
  </h4>
  } @if(event.imagePath) {
  <h3>Bild</h3>
  @for(image of album; track $index) {
  <div class="imgCol">
    <img
      [ngSrc]="image.thumb"
      (click)="open($index)"
      fill
      [alt]="'Bild zur Veranstaltung ' + event.title"
    />
  </div>
  } }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>
