<app-small-header title="Adventskalender" [snow]="true"></app-small-header>

<div class="content">
  <div class="container">
    <div class="row head-text">
      <div class="col-md-6">
        <h3 class="colored">FROHE WEIHNACHTEN</h3>
        <h3>Gewinne eine Jahresmitgliedschaft im Wert von 540 Euro</h3>
      </div>
      <div class="col-md-6">
        <ul>
          <li>
            <strong class="text-primary">Hauptgewinn</strong><br />
            Ein Jahr gratis tanzen im Wert von 540 Euro<br />
            Gewinnen Sie mit etwas Glück unsere Jahresmitgliedschaft und starten
            Sie das neue Jahr mit mehr Bewegung, Spaß und Gemeinschaft.
          </li>
          <li>
            <strong class="text-primary">2. Preis</strong><br />
            6 Monate gratis Tanzen
          </li>
          <li>
            <strong class="text-primary">3. Preis</strong><br />
            24 x 14 Tage gratis Tanzen
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        @if(christmas.length <= 0){
        <img
          src="./../../../assets/helloagain.png"
          alt="Adventskalender | ADTV Tanzschule Seidl Amberg"
        />} @else if(christmas[0].imagePath) {
        <img
          [src]="replaceImageUrl(christmas[0].imagePath)"
          alt="Adventskalender | ADTV Tanzschule Seidl Amberg"
        />
        }
      </div>
      <div class="col-lg-8">
        <form
          #contactForm="ngForm"
          class="row"
          (ngSubmit)="sendForm(contactForm)"
        >
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Dein Vorname</mat-label>
              <input
                matInput
                name="firstname"
                [(ngModel)]="contact.firstname"
                required
              />
              <mat-error>Vorname ist ein Pflichtfeld</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Dein Nachname</mat-label>
              <input
                matInput
                name="lastname"
                [(ngModel)]="contact.lastname"
                required
              />
              <mat-error>Nachname ist ein Pflichtfeld</mat-error>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Deine E-Mail Adresse</mat-label>
            <input
              matInput
              name="mail"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              type="email"
              [(ngModel)]="contact.mail"
              required
              #email="ngModel"
            />
            <mat-error *ngIf="email.touched && email.hasError('required')"
              >E-Mail Adresse ist ein Pflichtfeld
            </mat-error>
            <mat-error *ngIf="email.touched && email.hasError('pattern')"
              >Bitte gebe eine gültige E-Mail Adresse ein</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Deine Telefonnummer</mat-label>
            <input
              matInput
              name="tel"
              type="tel"
              [(ngModel)]="contact.phone"
              required
              #phone="ngModel"
            /><mat-error *ngIf="phone.touched && phone.hasError('required')"
              >Telefonnummer ist ein Pflichtfeld
            </mat-error> </mat-form-field
          ><small>* Pflichtfeld</small><br /><br />
          <button
            type="submit"
            [disabled]="!contactForm.form.valid"
            mat-raised-button
            color="primary"
          >
            Absenden
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="snow"></div>
