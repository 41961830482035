<app-small-header
  title="Unsere Räume"
  [breadcrumb]="breadCrumb"
></app-small-header>

<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Virtueller Rundgang</h2>
        @if(isGoogleMaps) {
        <div class="iframe-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!4v1699889771020!6m8!1m7!1sCAoSLEFGMVFpcE9CcEYyMzVGQWhIem00SnJLNkFKRmxaT1cxVWxqWGxZbWkyYmRn!2m2!1d49.437158853372!2d11.882014532632!3f29.36339582710824!4f-7.380505093045869!5f0.4000000000000002"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            title="Virtueller Rundgang - ADTV Tanzschule Seidl Amberg"
            referrerpolicy="no-referrer-when-downgrade"
            class="responsive-iframe"
          ></iframe>
        </div>
        } @else {
        <div class="consent-container">
          <div class="consent-bg"></div>
          <div class="consent-modal">
            <div class="consent-text">
              <h3>Rundgang (Google Maps) laden</h3>
              <p>
                Wenn Sie den Rundgang auf dieser Seite sehen möchten, werden
                personenbezogene Daten an den Betreiber von Google Maps gesendet
                und Cookies durch den Betreiber gesetzt. Daher ist es möglich,
                dass der Anbieter Ihre Zugriffe speichert und Ihr Verhalten
                analysieren kann. Die Datenschutzerklärung von Google Maps
                finden Sie unter:
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  title="https://policies.google.com/privacy"
                  class="link-google"
                  >https://policies.google.com/privacy</a
                >
              </p>
              <div>
                <button
                  class="btn btn-success btn-sm"
                  id="map-privacy-check-once"
                  type="button"
                  (click)="allowGoogleMap()"
                >
                  Rundgang auf dieser Seite laden
                </button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>

    <h2>Bildergalerie</h2>
    <div class="row">
      @for(image of album; track $index){
      <div class="col-md-3 col-sm-4">
        <div class="imgCol">
          <img
            [priority]="$index < 4 || image.thumb.includes('IMG_6059.webp')"
            [ngSrc]="image.thumb"
            fill
            [alt]="'Raum ' + $index + ' - ADTV Tanzschule Seidl Amberg'"
            (click)="open($index)"
          />
        </div>
      </div>
      }
    </div>
  </div>
</div>
