import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';

@Component({
  selector: 'app-coupon',
  standalone: true,
  imports: [CommonModule, SmallHeaderComponent],
  templateUrl: './coupon.component.html',
  styleUrl: './coupon.component.scss',
})
export class CouponComponent {}
