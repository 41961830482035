import { Component, inject, OnInit } from '@angular/core';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import { FormsModule, NgForm } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MailService } from '../../_services/mail.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { FormData } from '../../_models/mail.model';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-anniversary',
  standalone: true,
  imports: [
    SmallHeaderComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  templateUrl: './anniversary.component.html',
  styleUrl: './anniversary.component.scss',
})
export class AnniversaryComponent implements OnInit {
  public contact = {
    firstname: '',
    lastname: '',
    contract: '',
    startdate: '',
    mail: '',
    message: '',
  };

  public contract = ['Kindertanz', 'Ballett', 'Hip-Hop', 'Paartanz', 'Tanzfit'];

  public startdates = ['01.06.2024', '01.07.2024'];

  public privacyCheck = false;

  private mail = inject(MailService);
  private snackbar = inject(MatSnackBar);

  ngOnInit(): void {
    this.contract.sort((a, b) => a.localeCompare(b));
  }

  sendForm(contactForm: NgForm) {
    if (this.contact?.message === undefined || this.contact?.message === null) {
      this.contact.message = '';
    }
    const formData: FormData = {
      mail: environment.mailTo,
      subject: '15 Jahre Tanzschule Seidl Jubiläumsangebot',
      text: `Name: ${this.contact.firstname} ${this.contact.lastname}
      \n Kurs: ${this.contact.contract}
      \n Startdatum: ${this.contact.startdate}
      \nE-Mail: ${this.contact.mail}
      \n\n\nNachricht:\n${this.contact.message}
      `,
      html: `<table><tr><td>Name: </td><td>${
        this.contact.firstname + ' ' + this.contact.lastname
      }</td></tr><tr><td>Kurs: </td><td>${
        this.contact.contract
      }</td></tr><tr><td>Startdatum: </td><td>${
        this.contact.startdate
      }</td></tr></tr><tr><td>E-Mail: </td><td>${
        this.contact.mail
      }</td></tr></table><br><strong>Nachricht:</strong><br>${this.contact.message.replace(
        /\n/g,
        '<br />'
      )}`,
    };

    this.mail.sendForm(formData).subscribe({
      next: (value) => {
        this.snackbar.open('Erfolgreich versendet!', '', {
          duration: 3000,
          panelClass: 'bg-success',
        });
        contactForm.resetForm();
      },
      error: (error) => {
        console.log(error);
        this.snackbar.open('Fehler beim Senden!', '', {
          duration: 3000,
          panelClass: 'bg-danger',
        });
      },
      complete: () => console.log('Complete'),
    });
  }
}
