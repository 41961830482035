<div class="header">
  <div class="header-image"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 header-text">
        <h2>Herzlich Willkommen auf den Seiten der</h2>
        <h1>ADTV Tanzschule Seidl!</h1>
        <p>
          Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunden,
          Boogie Woogie, Discofox, Salsa, Hip Hop, Zumba, Kinder-Teenie-Tanzen.
        </p>
      </div>
      <div class="col-md-6 imgCol d-none d-lg-block">
        <div class="img1">
          <img
            ngSrc="./../../../assets/header/5DSR6363_s.webp"
            fill
            alt="Header 1 - ADTV Tanzschule Seidl Amberg"
          />
        </div>
        <div class="img2">
          <img
            ngSrc="./../../../assets/header/5DSR6467_s.webp"
            fill
            alt="Header 2 - ADTV Tanzschule Seidl Amberg"
          />
        </div>
        <div class="img3">
          <img
            ngSrc="./../../../assets/header/5DSR6492_s.webp"
            fill
            alt="Header 3 - ADTV Tanzschule Seidl Amberg"
          />
        </div>
        <div class="img4">
          <img
            ngSrc="./../../../assets/header/5DSR6559_s.webp"
            fill
            alt="Header 4 - ADTV Tanzschule Seidl Amberg"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="background">
    @for(alert of alerts; track $index){
    <div class="alert" [ngClass]="'alert-' + alert.type" role="alert">
      <div class="container">
        <div class="row">
          <div [ngClass]="alert.imagePath ? 'col-md-6' : 'col-md-12'">
            <h3 class="alert-heading">{{ alert.title }}</h3>
            <div class="alert-text" [innerHTML]="alert.description"></div>
          </div>
          @if(alert.imagePath){
          <div class="col-md-6 text-end">
            <img
              [src]="replaceImageUrl(alert.imagePath)"
              (click)="open($index)"
              style="max-height: 300px"
              [alt]="alert.title + ' | Tanzschule Seidl Amberg'"
              srcset=""
            />
          </div>
          }
        </div>
      </div>
    </div>
    }
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h3 class="colored">BEI UNS IST FÜR JEDEN WAS DABEI!</h3>
        <h3>…denn jeder kann tanzen…</h3>
      </div>
      <div class="col-md-6">
        <p>
          Schon die kleinsten Musikbegeisterten lernen bei uns die ersten
          Tanzschritte. In den HipHop- und Ballettstunden werden diese später zu
          eleganten Sprüngen oder coolen Moves.
        </p>
        <p>
          Für alle die auch zu zweit das Tanzbein schwingen möchten, bieten wir
          zahlreiche Paartänze von Standard- bis Lateintanz, von
          Hochzeitscrashkurs bis Hobbytanz. Ob Anfänger oder langjähriger Tänzer
          – Tanzen macht einfach Spaß!
        </p>
        <p>
          Wir sind ein junges, dynamisches Team. Der besondere Charakter findet
          sich in uns Tanzlehrern, Auszubildenden und Assistenten wieder. Wir
          können nie genug bekommen vom Tanzen und bilden uns stetig fort,
          dadurch kommt unser reichhaltiges Angebot zu Stande.<br />
          Aus Leidenschaft zur Bewegung bieten wir Kurse
        </p>
        <ul>
          <li>von klassisch bis modern</li>
          <li>für jung bis alt</li>
          <li>mit oder ohne Partner</li>
          <li>sportliche Fitness oder gemütliches Schunkeln.</li>
        </ul>
        <p>
          <strong
            >Haben wir Sie überzeugt, heißen wir Sie herzlich
            willkommen!</strong
          >
        </p>
      </div>
    </div>
  </div>

  <div class="background">
    <div class="container center">
      <h2>Unsere Kursübersicht</h2>
      <div class="spacer-container">
        <span class="spacer"></span>
      </div>
      <!--
        TODO: Enable when angular update allows to load standalone components with defer
        https://github.com/angular/angular/pull/52881
      -->
      <!-- @defer (on viewport) { -->
      <app-courses-box></app-courses-box>
      <!-- } @placeholder { <span>Kurse werden geladen...</span> } -->
    </div>
  </div>

  <div
    class="container cta center align-middle justify-content-center align-items-center"
  >
    <div
      class="row center align-middle justify-content-center align-items-center"
    >
      <div
        class="col-md-4 align-middle justify-content-center align-items-center"
      >
        Kontaktiere uns!
      </div>
      <div
        class="col-md-4 align-middle justify-content-center align-items-center"
      >
        09621 / 600 323<br />
        <small
          ><a href="mailto:info@tanzen-amberg.de"
            >info&#64;tanzen-amberg.de</a
          ></small
        >
      </div>
      <div
        class="col-md-4 align-middle justify-content-center align-items-center"
      >
        <button mat-raised-button color="primary" href="/kontakt">
          Kontakt
        </button>
      </div>
    </div>
  </div>
</div>
