<div class="small-header" [ngClass]="snow ? 'snow-header' : ''">
  <div class="small-header-text">
    <h1 [innerHtml]="title"></h1>
    <h3>
      @for(bread of breadcrumb; track $index){
      <span>
        <a [title]="bread.title" [href]="bread.link">{{ bread.title }}</a>
        @if($index < breadcrumb.length - 1){
        <span> / </span>
        }
      </span>
      }
    </h3>
  </div>
  @if(snow){
  <div class="snow"></div>
  }
</div>
