import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('ADTV Tanzschule Seidl Amberg');
    this.meta.addTags([
      {
        name: 'keywords',
        content:
          'Tanzen Amberg, Tanzschule, Tanzschule Amberg, Tanschule Seidl, Seidl, Schwandner, Tanschule Schwandner, Sulzbach, Sulzbach-Rosenberg, Hip-Hop, Balett, Discofox, Paartanz, Salsa, West-Cost-Swing, Zumba, Zumba Gold, Zumba Fitness',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'ADTV Tanzschule Seidl Amberg' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' },
      { language: 'de' },
    ]);

    this.meta.updateTag({
      name: 'description',
      content:
        'Herzlich Willkommen auf den Seiten der ADTV Tanzschule Seidl! Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunde, Discofox, Salsa, Hip Hop, Ballett, Zumba, Kinder-Teenie-Tanzen, Linedance uvm',
    });
  }
}
