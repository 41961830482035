<app-small-header title="Gutscheine"></app-small-header>

<div class="content">
  <div class="container">
    <div class="embed-container">
      <iframe
        loading="lazy"
        width="560"
        height="500"
        title="Gutschein für die ADTV Tanzschule Seidl Amberg"
        allowfullscreen=""
        src="https://ts-schwandner.nimbuscloud.at/?r=/registration/iframe/coupon"
      ></iframe>
    </div>
  </div>
</div>
